import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';

@Injectable({providedIn: 'root'})
export class ReportAttachmentsApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(reportId: string, id: string): Observable<void> {
        return this._modelApiService.delete$(`/reports/${reportId}/attachments/${id}`);
    }
}
