import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IReportApi} from '@models/reports/report/report.interfaces';
import {ModelApiService} from '@models/model.api.service';

@Injectable({providedIn: 'root'})
export class ReportsApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(id: string): Observable<void> {
        return this._modelApiService.delete$(`/reports/${id}`);
    }

    get$(id: string): Observable<IReportApi> {
        return this._modelApiService.get$(`/reports/${id}`);
    }
}
