import {inject, Injectable} from '@angular/core';
import ReportAttachment from '@models/reports/report/attachments/attachment/report-attachment.model';
import {IReportAttachmentApi} from '@models/reports/report/attachments/attachment/report-attachment.interfaces';
import {ReportAttachmentsApiService} from '@models/reports/report/attachments/report-attachments.api.service';
import {Observable} from 'rxjs';
import Report from '@models/reports/report/report.model';
import {NgReportAttachment} from '@legacy/app/managers/ressources';

@Injectable({providedIn: 'root'})
export class ReportAttachmentFactory {
    private _reportAttachmentsApiService = inject(ReportAttachmentsApiService);

    create(reportAttachmentApi: IReportAttachmentApi): ReportAttachment {
        return new ReportAttachment(reportAttachmentApi.uuid, reportAttachmentApi.id);
    }

    delete$(report: Report, reportAttachment: ReportAttachment): Observable<void> {
        return this._reportAttachmentsApiService.delete$(report.id.toString(), reportAttachment.id.toString());
    }

    ngCreate(ngReportAttachment: NgReportAttachment): ReportAttachment {
        return new ReportAttachment(ngReportAttachment.uuid, ngReportAttachment.id);
    }
}
