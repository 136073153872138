import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {catchError, map, take, takeUntil, tap} from 'rxjs/operators';
import {combineLatest, Observable, of, ReplaySubject, Subject, switchMap, throwError} from 'rxjs';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EditorOptions} from 'tinymce';
import {TinymceService} from '@shared/tinymce/tinymce.service';
import {DocumentFactory} from '@models/documents/document/document.factory';
import {DocumentService} from '@models/documents/document/document.service';
import {EstimationService} from '@models/estimations/estimation/estimation.service';
import Estimation from '@models/estimations/estimation/estimation.model';
import Document from '@models/documents/document/document.model';
import {ModalService} from '@shared/modal/modal.service';
import {EmailEditService} from '@models/emails/email/email.edit.service';
import {EstimationRapportService} from '@features/estimations/estimation/rapport/estimation-rapport.service';
import {
    EstimationRapportActionsMainComponent
} from '@features/estimations/estimation/rapport/actions/estimation-rapport.actions-main.component';
import {IEstimationRapportActionsDataOptions} from '@features/estimations/estimations.interfaces';

@Component({selector: 'layout-estimation-rapport-write', templateUrl: 'layout-estimation.rapport-write.component.html'})
export class AppLayoutEstimationRapportWriteComponent implements OnDestroy, OnInit {
    static readonly error = {EMPTY_COURRIER: 'EMPTY_COURRIER', EMPTY_DOCUMENT: 'EMPTY_DOCUMENT'};
    // @todo Faire un tour pour factoriser en recherchant "messages"
    static readonly errorMessages = {
        [AppLayoutEstimationRapportWriteComponent.error.EMPTY_COURRIER]: 'Le courrier introductif au mandat doit être saisi.',
        [AppLayoutEstimationRapportWriteComponent.error.EMPTY_DOCUMENT]: 'Le rapport d\'évaluation doit être saisi.',
        TITLE: 'Données incomplètes',
    };
    private _activatedRoute = inject(ActivatedRoute);
    private _callToActionService = inject(CallToActionService);
    private _documentFactory = inject(DocumentFactory);
    private _documentService = inject(DocumentService);
    private _emailEditService = inject(EmailEditService);
    private _estimationRapportService = inject(EstimationRapportService);
    private _estimationService = inject(EstimationService);
    private _modalService = inject(ModalService);
    private _router = inject(Router);
    private _tinymceService = inject(TinymceService);
    private _courrierSource = new ReplaySubject<Document>(1);
    private _courrier$ = this._courrierSource.asObservable();
    private _documentSource = new ReplaySubject<Document>(1);
    private _document$ = this._documentSource.asObservable();
    private _documentPathSource = new ReplaySubject<string>(1);
    private _documentPath$ = this._documentPathSource.asObservable();
    private _editorCourrierOptions!: EditorOptions;
    private _editorDocumentOptions!: EditorOptions;
    private _estimation$!: Observable<Estimation>;
    private readonly _onDestroy$ = new Subject<void>();

    get courrier$(): Observable<Document> {
        return this._courrier$;
    }

    get document$(): Observable<Document> {
        return this._document$;
    }

    get documentPath$(): Observable<string> {
        return this._documentPath$;
    }

    get editorCourrierOptions(): EditorOptions {
        return this._editorCourrierOptions;
    }

    get editorDocumentOptions(): EditorOptions {
        return this._editorDocumentOptions;
    }

    get estimation$(): Observable<Estimation> {
        return this._estimation$;
    }

    ngOnInit(): void {
        this._estimation$ = of(this._estimationService.getCurrentFromNg());
        this._estimation$.pipe(
            tap(estimation => this._estimationRapportService.init(estimation)),
            tap(estimation => {
                const options: IEstimationRapportActionsDataOptions = {
                    enabledActions: {
                        generate: !estimation.isArchived(),
                        print: true,
                        save: !estimation.isArchived(),
                        send: true,
                    },
                };

                this._callToActionService.setDynamicComponentLoading({
                    component: EstimationRapportActionsMainComponent,
                    data: {options},
                });
            }),
            switchMap(_ => this._estimationRapportService.redirect$(this._router.url, this._activatedRoute)),
            switchMap(isRedirect => {
                if (isRedirect) {
                    return of();
                }

                return this.getDocuments$();
            }),
            take(1),
        ).subscribe();
        this._callToActionService.clicked$.pipe(
            switchMap(callToActionClicked => {
                let action$ = of(undefined as unknown as string);

                if (callToActionClicked.action === EstimationRapportActionsMainComponent.actions.GENERATE) {
                    action$ = this._callToActionService.actionExec$(this.generate$());
                } else if (callToActionClicked.action === EstimationRapportActionsMainComponent.actions.PRINT) {
                    action$ = this._callToActionService.actionExec$(this.print$());
                } else if (callToActionClicked.action === EstimationRapportActionsMainComponent.actions.SAVE) {
                    action$ = this._callToActionService.actionExec$(this.save$());
                } else if (callToActionClicked.action === EstimationRapportActionsMainComponent.actions.SEND) {
                    action$ = this._callToActionService.actionExec$(this.send$());
                }

                return action$.pipe(catchError((error: unknown) => {
                    if (typeof error !== 'string'
                        || ![AppLayoutEstimationRapportWriteComponent.error.EMPTY_COURRIER, AppLayoutEstimationRapportWriteComponent.error.EMPTY_DOCUMENT].includes(error)) {
                        return throwError(() => error);
                    }

                    return this._modalService.openInformation$({
                        comments: AppLayoutEstimationRapportWriteComponent.errorMessages[error],
                        title: AppLayoutEstimationRapportWriteComponent.errorMessages.TITLE,
                        status: ModalService.status.WARNING,
                    }).pipe(map(() => undefined));
                }));
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(url => {
            if (url) {
                this._router.navigateByUrl(url);
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    getDocuments$(): Observable<void> {
        return this.estimation$.pipe(switchMap(estimation => {
            const documentCourrier$ = [
                this._documentFactory.get$(estimation.rapportId).pipe(
                    switchMap(rapport => this._documentService.getStylesheetLinks$(rapport).pipe(
                        tap(content_css => this._editorDocumentOptions = this._tinymceService.getOptions('complete', {
                            content_css,
                            plugins: [TinymceService.plugins.PAGE_BREAK],
                        })),
                        map(_ => rapport),
                    )),
                    tap(document => this._documentSource.next(document)),
                ),
            ];

            if (estimation.courrierIntroductifRapportId) {
                documentCourrier$.push(this._documentFactory.get$(estimation.courrierIntroductifRapportId).pipe(
                    switchMap(courrier => this._documentService.getStylesheetLinks$(courrier).pipe(
                        tap(content_css => this._editorCourrierOptions = this._tinymceService.getOptions('complete', {
                            content_css,
                            plugins: [TinymceService.plugins.PAGE_BREAK],
                        })),
                        map(_ => courrier),
                    )),
                    tap(courrier => this._courrierSource.next(courrier)),
                ));
            } else {
                this._courrierSource.next(undefined!);
            }

            return combineLatest(documentCourrier$).pipe(
                switchMap(documentCourrier => {
                    if (estimation.isArchived()) {
                        const courrier = documentCourrier?.[1];
                        const document = documentCourrier[0];

                        return this._documentService.getMedia$(document, courrier).pipe(
                            map(media => media.linkDownload),
                            map(documentPath => this._documentPathSource.next(documentPath)),
                        );
                    }

                    return of();
                }),
            );
        }));
    }

    generate$(): Observable<string> {
        return this._estimation$.pipe(
            switchMap(estimation => this._estimationService.clearRapportWithNgCurrent$(estimation)),
            map(isAccepted => isAccepted ? this._router.createUrlTree(
                [`../${EstimationRapportService.ROUTING[EstimationRapportService.STATUTS.EDIT]}`],
                {relativeTo: this._activatedRoute},
            ).toString() : undefined!),
        );
    }

    print$(): Observable<string> {
        return this.saveCurrent$().pipe(
            switchMap(([document, courrier]: [Document, Document]) => this._documentService.read$(document, courrier)),
            switchMap(_ => this.estimation$),
            switchMap(estimation => this.remettreRapport$(estimation)),
            map(_ => undefined!),
        );
    }

    remettreRapport$(estimation: Estimation): Observable<boolean> {
        if (estimation.isArchived()) {
            return of(!!estimation.dateRemise);
        }

        return this._estimationService.remettreRapport$(estimation);
    }

    save$(): Observable<string> {
        return this.saveCurrent$().pipe(map(_ => undefined!));
    }

    saveCurrent$(): Observable<[Document, Document]> {
        return combineLatest([this.saveCurrentDocument$(), this.saveCurrentCourrier$()]);
    }

    saveCurrentCourrier$(): Observable<Document> {
        return this.courrier$.pipe(
            switchMap(courrier => courrier ? this._documentService.save$(courrier) : of(undefined as unknown as Document)),
            catchError((error: unknown) => {
                if (typeof error === 'string' && error === DocumentService.error.EMPTY_CONTENT) {
                    return throwError(() => AppLayoutEstimationRapportWriteComponent.error.EMPTY_COURRIER);
                }

                return throwError(() => error);
            }),
        );
    }

    saveCurrentDocument$(): Observable<Document> {
        return this.document$.pipe(
            switchMap(document => this._documentService.save$(document)),
            catchError((error: unknown) => {
                if (typeof error === 'string' && error === DocumentService.error.EMPTY_CONTENT) {
                    return throwError(() => AppLayoutEstimationRapportWriteComponent.error.EMPTY_DOCUMENT);
                }

                return throwError(() => error);
            }),
        );
    }

    send$(): Observable<string> {
        return this.saveCurrent$().pipe(
            switchMap(([document, courrier]: [Document, Document]) => combineLatest([
                of(document),
                this._documentService.getMedia$(document, courrier).pipe(map(media => [media])),
                this._estimation$,
            ])),
            switchMap(([document, attachments, estimation]) => this._emailEditService.fromEstimationRapport$(estimation, document.titre, attachments).pipe(
                switchMap(isRemis => isRemis ? this.remettreRapport$(estimation) : of(undefined)),
            )),
            map(_ => undefined!),
        );
    }
}
