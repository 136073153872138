<div class="tw-h-full xl:tw-mx-28">
  <div class="tw-h-full tw-mx-[-15px] md:tw-mx-0">
    @if (estimation$ | async; as estimation) {
      <div class="tw-flex tw-gap-4 tw-flex-col">
        <div class="tw-flex tw-items-center tw-h-12 md:tw-px-0" [ngClass]="{'tw-px-4': !hasSelectedItems}">
          @if (hasSelectedItems) {
            @if (searchCriteria.isSourceCReferences()) {
              <app-collection-selection-bar [listName]="referencesListOptions.nameSelection!"
                                            class="tw-block tw-overflow-hidden tw-w-full md:tw-rounded-lg"/>
            }

            @if (searchCriteria.isSourceCVentes()) {
              <app-collection-selection-bar [listName]="ventesListOptions.nameSelection!"
                                            class="tw-block tw-overflow-hidden tw-w-full md:tw-rounded-lg"/>
            }
          } @else {
            <div class="tw-w-full tw-flex tw-justify-between tw-items-center">
              <div class="tw-flex tw-items-center tw-gap-8">
                <a [routerLink]="redirectionRoute" class="tw-border-r-2 tw-pr-8 tw-text-gray-500">
                  <fa-icon [icon]="'arrow-left'" size="xl"/>
                </a>

                <div class="tw-text-gray-700 tw-text-lg">Recherche de références</div>
              </div>

              <app-call-to-action type="{{CALL_TO_ACTION_MAIN}}"/>
            </div>
          }
        </div>

        @if (searchCriteria.isSourceCReferences()) {
          @if (errorCityscanLocalizable) {
            <div class="tw-text-lg tw-text-center">
              L'adresse <span class="tw-italic">"{{ estimation.bien.fullInlineAdresse }}"</span> de votre bien en
              estimation n'a pas été trouvée par <app-noteo-icon-text-cityscan/>.<br><br>Modifiez la dans la partie
              <a [routerLink]="'/app/estimations/' + estimation.id + '/edit/general'">
                "Fiche" de votre bien en estimation
              </a>
              afin que <app-noteo-icon-text-cityscan/> vous retourne des références.
            </div>
          } @else {
            @if (cReferences$ | async; as cReferences) {
              @if (cReferences.total > 0) {
                <app-references-list [options]="referencesListOptions" (changedTri)="changedTri($event)"
                                     [cReferences$]="cReferences$"/>
              } @else {
                <div class="tw-mb-4 sm:tw-mb-8 tw-mx-auto tw-w-full tw-pt-6 tw-px-4 tw-text-center">
                  <p class="tw-mx-auto tw-mb-4 tw-text-base tw-text-gray-500 sm:tw-text-lg md:tw-text-xl">
                    Aucune référence ne correspond avec les critères saisis.<br><br>Élargissez-les afin de pouvoir
                    ajouter des références à votre bien en estimation.
                  </p>
                </div>
              }
            } @else {
              <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
            }
          }
        }

        @if (searchCriteria.isSourceCVentes()) {
          @if (cVentes$ | async; as cVentes) {
            @if (cVentes.total > 0) {
              <app-ventes-list [cVentes$]="cVentes$" [options]="ventesListOptions" (changedTri)="changedTri($event)"/>
            } @else {
              <div class="tw-mb-4 sm:tw-mb-8 tw-mx-auto tw-w-full tw-pt-6 tw-px-4 tw-text-center">
                <p class="tw-mx-auto tw-mb-4 tw-text-base tw-text-gray-500 sm:tw-text-lg md:tw-text-xl">
                  Aucun bien en vente archivé ne correspond avec les critères saisis.<br><br>Élargissez-les afin de
                  pouvoir ajouter des références à votre bien en estimation.
                </p>
              </div>
            }
          } @else {
            <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
          }
        }
      </div>
    } @else {
      <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
    }
  </div>
</div>
