import angularJS from '@shared/angularJS/global.ng';
import {find, remove} from "lodash";
import {SortConst} from '@shared/constants';
import {IModule, IPromise, IQService} from 'angular';
import Phone from '@models/phones/phone/phone.model';
import {
    NgEmailAttachmentManager, NgEmailDossierManager, NgEmailRecipientManager, NgManager
} from '@legacy/app/managers/managers';
import {
    NgBienDossier, NgEmail, NgEmailAttachment, NgEmailDossier, NgEmailRecipient, NgMedia
} from '@legacy/app/managers/ressources';
import Etude from '@models/etudes/etude/etude.model';
import {filter, map} from 'rxjs/operators';
import {EtudeService} from '@models/etudes/etude/etude.service';
import Email from '@models/emails/email/email.model';
import {from} from 'rxjs';
import User from '@models/users/user/user.model';
import {UserService} from '@models/users/user/user.service';
import EmailRecipient from '@models/emails/email/recipients/recipient/email-recipient.model';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';
import {DossierType} from '@models/dossiers/dossiers.interfaces';

export default function getManagersEmail(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('EmailManager', manager);

        /**
         * Manager email
         *
         * @param BaseManager
         * @param EmailRecipientManager
         * @param EmailAttachmentManager
         * @param EmailDossierManager
         * @param $q
         * @param Ng2EtudeService
         * @param Ng2UserService
         * @returns {EmailManager}
         */
        manager.$inject = ['BaseManager', 'EmailRecipientManager', 'EmailAttachmentManager', 'EmailDossierManager', '$q', 'Ng2EtudeService', 'Ng2UserService'];
        function manager(baseManager: NgManager,
                         emailRecipientManager: NgEmailRecipientManager,
                         emailAttachmentManager: NgEmailAttachmentManager,
                         emailDossierManager: NgEmailDossierManager,
                         $q: IQService,
                         ng2EtudeService: EtudeService,
                         ng2UserService: UserService) {
            const classResource = baseManager.getClass();
            let currentEtude: Etude;
            let currentUser: User;

            ng2EtudeService.current$.pipe(filter(etude => !!etude)).subscribe(etude => currentEtude = etude);
            ng2UserService.current$.pipe(filter(user => !!user)).subscribe(user => currentUser = user);

            /**
             * Routing
             */
            baseManager.addRoute('emails.cget', {path: '/user/emails', method: 'GET'});
            baseManager.addRoute('emails.cget-ventes', {path: '/ventes/{venteId}/emails', method: 'GET'});
            baseManager.addRoute('emails.cget-locations', {path: '/locations/{locationId}/emails', method: 'GET'});
            baseManager.addRoute('emails.cget-estimations', {
                path: '/estimations/{estimationId}/emails',
                method: 'GET'
            });
            baseManager.addRoute('emails.cget-demandeurs', {path: '/demandeurs/{demandeurId}/emails', method: 'GET'});
            baseManager.addRoute('emails.get', {path: '/emails/{id}', method: 'GET'});
            baseManager.addRoute('emails.insert', {path: '/user/emails', method: 'POST'});
            baseManager.addRoute('emails.edit', {path: '/emails/{id}', method: 'PUT'});
            baseManager.addRoute('emails.send', {path: '/emails/{id}/send', method: 'PATCH'});
            baseManager.addRoute('emails.remove', {path: '/emails/{id}', method: 'DELETE'});

            /**
             * EmailManager object
             *
             * @constructor
             */
            angular.extend(EmailManager.prototype, baseManager.__proto__);
            EmailManager.prototype.eskManager = {prefixRoute: 'emails', collectionName: 'items'};
            function EmailManager() {
            }

            /**
             * Create a Email object
             *
             * @param ng2Email
             * @returns manager.Email
             */
            EmailManager.prototype.createFromNg2 = function (ng2Email: Email): NgEmail {
                if (!ng2Email) {
                    return undefined!;
                }

                return this.create({
                    message: ng2Email.message,
                    id: ng2Email.id,
                    recipients: ng2Email.recipients.map(recipient => emailRecipientManager.createFromNg2(recipient)),
                    statut: ng2Email.statut,
                    subject: ng2Email.subject,
                    uuid: ng2Email.uuid,
                });
            };

            /**
             * Get signature of current User
             *
             * @returns {string}
             */
            EmailManager.prototype.getCurrentUserSignature = function () {
                if (angular.isDefined(currentUser.emailSignature) && currentUser.emailSignature !== null) {
                    return currentUser.emailSignature;
                }

                const phoneFixe = currentUser.cPhones.getByType(Phone.types.FIXE);
                const phoneMobile = currentUser.cPhones.getByType(Phone.types.MOBILE);
                let signature = currentUser.prenom + " " + currentUser.nom + "<br>" + currentEtude.crpcen + " : " + currentEtude.raisonSociale;

                if (phoneFixe?.hasValidNumero() || phoneMobile?.hasValidNumero()) {
                    signature += "<br>Tél : ";
                    if (phoneFixe?.hasValidNumero()) {
                        signature += phoneFixe.numero;
                    }

                    if (phoneFixe?.hasValidNumero() && phoneMobile?.hasValidNumero()) {
                        signature += " / ";
                    }

                    if (phoneMobile?.hasValidNumero()) {
                        signature += phoneMobile.numero;
                    }
                }

                return signature;
            };

            /**
             * Get emails from dossier
             *
             * @param dossier
             * @returns {*}
             */
            EmailManager.prototype.getFromDossier = function (dossier: NgBienDossier) {
                const params: Record<string, unknown> = {tris: {sendingDate: SortConst.DESCENDING}};

                params[dossier._esk.typeDossier + 'Id'] = dossier.id;

                return this.get('cget-' + dossier._esk.typeDossier + 's', params);
            };

            /**
             * Send Email
             *
             * @param ng2Email
             * @returns {*}
             */
            EmailManager.prototype.send$ = function (ng2Email: Email) {
                const email = this.createFromNg2(ng2Email);

                return from(email.send()).pipe(map(_ => true));
            };

            /**
             * Create a Email object
             *
             * @param data
             * @returns manager.Email object
             */
            EmailManager.prototype.create = function (data: unknown) {
                if (angular.isArray(data)) {
                    var emails = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        emails.push(new Email(data[i]));
                    }

                    return emails;
                }

                // @ts-ignore
                return new Email(data);
            };

            /**
             * Email object
             *
             * @param data
             * @constructor
             */
            angular.extend(Email.prototype, classResource.prototype);
            Email.prototype.eskManager = {prefixRoute: 'emails'};
            function Email(this: NgEmail, data: unknown) {
                this.message = "<br><br>" + EmailManager.prototype.getCurrentUserSignature();
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id};
            }

            /**
             * Extend the existing email with new data
             *
             * @param data
             * @returns {Email}
             */
            Email.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                if (angular.isArray(this.recipients)) {
                    this.recipients = emailRecipientManager.create(this.recipients, this.id);
                } else {
                    this.recipients = [];
                }

                if (angular.isArray(this.attachments)) {
                    this.attachments = emailAttachmentManager.create(this.attachments, this.id);
                } else {
                    this.attachments = [];
                }

                if (angular.isArray(this.dossiers)) {
                    this.dossiers = emailDossierManager.create(this.dossiers, this.id);
                } else {
                    this.dossiers = [];
                }

                return this;
            };

            /**
             * Sanitize object Email before send to API
             *
             * @returns sanitizeObject
             */
            Email.prototype.sanitize = function () {
                const sanitizeObject = classResource.prototype.sanitize.call(this);

                delete sanitizeObject.recipients;
                delete sanitizeObject.attachments;
                delete sanitizeObject.dossiers;

                return sanitizeObject;
            };

            /**
             * Save Resource
             *
             * @returns {Promise}
             */
            Email.prototype.save = function () {
                const self = this;
                const recipients = angular.copy(self.recipients);
                const attachments = angular.copy(self.attachments);
                const dossiers = angular.copy(self.dossiers);

                return classResource.prototype.save.call(self).then(function () {
                    const promises: IPromise<unknown>[] = [];

                    self.attachments = [];
                    angular.forEach(attachments, function (attachment) {
                        if (angular.isDefined(attachment.id)) {
                            self.attachments.push(attachment);
                        } else {
                            attachment._esk.defaultRouteParams.emailId = self.id;
                            promises.push(attachment.save().then(function () {
                                self.attachments.push(attachment);
                            }));
                        }
                    });

                    self.dossiers = [];
                    angular.forEach(dossiers, function (dossier) {
                        if (angular.isDefined(dossier.id)) {
                            self.dossiers.push(dossier);
                        } else {
                            dossier._esk.defaultRouteParams.emailId = self.id;
                            promises.push(dossier.save().then(function () {
                                self.dossiers.push(dossier);
                            }));
                        }
                    });

                    self.recipients = [];
                    angular.forEach(recipients, function (recipient) {
                        recipient._esk.defaultRouteParams.emailId = self.id;
                        promises.push(recipient.save().then(function () {
                            self.recipients.push(recipient);
                        }));
                    });

                    return $q.all(promises).then(() => self);
                });
            };

            /**
             * Save and send email
             *
             * @returns {promise}
             */
            Email.prototype.send = function () {
                const self = this;

                return self.save().then(() => self.patch('send'));
            };

            /**
             * Add recipient to email
             *
             * @param emailAddress
             * @param type
             * @param name
             */
            Email.prototype.addRecipient = function (emailAddress: string, type: string, name: string) {
                if (![EmailRecipient.types.TO, EmailRecipient.types.CC, EmailRecipient.types.BCC].includes(type)) {
                    throw new Error("Le type de destinataires doit être '" + EmailRecipient.types.TO + "', '" + EmailRecipient.types.CC + "' ou '" + EmailRecipient.types.BCC + "'. '" + type + "' reçu.");
                }

                this.recipients.push(emailRecipientManager.create({emailAddress, type, name}, this.id));
            };

            /**
             * Remove recipient from email
             *
             * @param recipient
             */
            Email.prototype.removeRecipient = function (recipient: NgEmailRecipient) {
                const self = this;
                const recipientToRemove = find(self.recipients, {_esk: {uuid: recipient._esk.uuid}});

                if (angular.isDefined(recipientToRemove.id)) {
                    recipientToRemove.remove().then(() => remove(self.recipients, recipientToRemove));
                } else {
                    remove(self.recipients, recipientToRemove);
                }
            };

            /**
             * Add attachment to email
             *
             * @param media
             */
            Email.prototype.addAttachmentFromMedia = function (media: NgMedia) {
                this.attachments.push(emailAttachmentManager.create({media: {id: media.id}}, this.id));
            };

            /**
             * Remove attachment from email
             *
             * @param attachment
             */
            Email.prototype.removeAttachment = function (attachment: NgEmailAttachment) {
                const self = this;
                var attachmentToRemove = find(self.attachments, {_esk: {uuid: attachment._esk.uuid}});

                if (angular.isDefined(attachmentToRemove.id)) {
                    attachmentToRemove.remove().then(() => remove(self.attachments, attachmentToRemove));
                } else {
                    remove(self.attachments, attachmentToRemove);
                }
            };

            /**
             * Add dossier to email
             *
             * @param dossier_id
             * @param dossierType
             */
            Email.prototype.addDossier = function (dossier_id: number, dossierType: DossierType) {
                if (![DossierTypesConst.DEMANDEUR, DossierTypesConst.VENTE].includes(dossierType)) {
                    throw new Error("Le type de dossier doit être '" + DossierTypesConst.VENTE + "' ou '" +
                        DossierTypesConst.DEMANDEUR + "'. '" + dossierType + "' reçu.");
                }

                this.dossiers.push(emailDossierManager.create({typeId: dossier_id, type: dossierType}, this.id));
            };

            /**
             * Remove dossier from email
             *
             * @param dossier
             */
            Email.prototype.removeDossier = function (dossier: NgEmailDossier) {
                const self = this;
                var dossierToRemove = find(self.dossiers, {_esk: {uuid: dossier._esk.uuid}});

                if (angular.isDefined(dossierToRemove.id)) {
                    dossierToRemove.remove().then(() => remove(self.dossiers, dossierToRemove));
                } else {
                    remove(self.dossiers, dossierToRemove);
                }
            };

            // @ts-ignore
            return new EmailManager();
        }
    })(angularJS);
}
