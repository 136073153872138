import AModel from '@models/model.abstract';

export default class ReportAttachment extends AModel {
    private readonly _id: number;

    constructor(uuid: string, id: number) {
        super(uuid);
        this._id = id;
    }

    get id(): number {
        return this._id;
    }
}
