import {NgModule} from '@angular/core';
import {AppLoadMoreComponent} from '@shared/load/more/load-more.component';
import {CommonModule} from '@angular/common';
import {AppLoadObsPipe} from '@shared/load/obs/load-obs.pipe';
import {AppLoaderComponent} from '@shared/load/loader/loader.component';
import {FaModule} from '@shared/fontawesome/fa.module';
import {AppLoadProgressBarComponent} from '@shared/load/progress-bar/load-progress-bar.component';

@NgModule({
    declarations: [AppLoaderComponent, AppLoadMoreComponent, AppLoadProgressBarComponent],
    exports: [AppLoaderComponent, AppLoadMoreComponent, AppLoadObsPipe, AppLoadProgressBarComponent],
    imports: [AppLoadObsPipe, CommonModule, FaModule],
})
export class LoadModule {
}
