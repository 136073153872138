import AModel from '@models/model.abstract';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {ValidationErrorsConst} from '@models/model.constants';
import {IValidationError} from '@models/model.interfaces';

export default class Reference extends AModel {
    static readonly statuts = {NEW: AModel.STATUT_NEW};
    static readonly NO_ADRESSE = 'Aucune adresse renseignée';
    private _adresse!: string;
    private _communeId!: number;
    private _dateVente!: string;
    private _distance!: number;
    // @todo Voir pour utiliser un id
    private _epoque!: DictionaryItem;
    private _epoqueId!: number;
    private _linkCommune!: string;
    private _loyer!: number;
    // @todo Voir pour utiliser un id
    private _nature!: DictionaryItem;
    private _natureId!: number;
    private _nombreChambres!: number;
    private _nombreEtages!: number;
    private _nombrePieces!: number;
    private _prixM2 = 0;
    private _prixVente!: number;
    private _superficie!: number;
    private _surfaceCadastrale!: number;

    get adresse(): string {
        return this._adresse;
    }

    set adresse(value: string) {
        this._adresse = value?.trim() || Reference.NO_ADRESSE;
    }

    get communeId(): number {
        return this._communeId;
    }

    set communeId(value: number) {
        this._communeId = value;
    }

    get dateVente(): string {
        return this._dateVente;
    }

    set dateVente(value: string) {
        this._dateVente = value;
    }

    get distance(): number {
        return this._distance;
    }

    set distance(value: number) {
        this._distance = value;
    }

    get epoque(): DictionaryItem {
        return this._epoque;
    }

    set epoque(value: DictionaryItem) {
        this._epoque = value;
    }

    get epoqueId(): number {
        return this._epoqueId;
    }

    set epoqueId(value: number) {
        this._epoqueId = value;
    }

    get linkCommune(): string {
        return this._linkCommune;
    }

    set linkCommune(value: string) {
        this._linkCommune = value;
    }

    get loyer(): number {
        return this._loyer;
    }

    set loyer(value: number) {
        this._loyer = value;
    }

    get nature(): DictionaryItem {
        return this._nature;
    }

    set nature(value: DictionaryItem) {
        this._nature = value;
    }

    get natureId(): number {
        return this._natureId;
    }

    set natureId(value: number) {
        this._natureId = value;
    }

    get nombreChambres(): number {
        return this._nombreChambres;
    }

    set nombreChambres(value: number) {
        this._nombreChambres = value;
    }

    get nombreEtages(): number {
        return this._nombreEtages;
    }

    set nombreEtages(value: number) {
        this._nombreEtages = value;
    }

    get nombrePieces(): number {
        return this._nombrePieces;
    }

    set nombrePieces(value: number) {
        this._nombrePieces = value;
    }

    get prixM2(): number {
        return this._prixM2;
    }

    get prixVente(): number {
        return this._prixVente;
    }

    set prixVente(value: number) {
        this._prixVente = value;
        this.setPrixM2();
    }

    get superficie(): number {
        return this._superficie;
    }

    set superficie(value: number) {
        this._superficie = value;
        this.setPrixM2();
    }

    get surfaceCadastrale(): number {
        return this._surfaceCadastrale;
    }

    set surfaceCadastrale(value: number) {
        this._surfaceCadastrale = value;
    }

    getValidationErrors(): IValidationError[] {
        const validationErrors: IValidationError[] = [];

        if (!this.dateVente) {
            validationErrors.push(ValidationErrorsConst.DATE_VENTE);
        }

        if (!this.communeId) {
            validationErrors.push(ValidationErrorsConst.COMMUNE);
        }

        if (!this.nature) {
            validationErrors.push(ValidationErrorsConst.NATURE);
        }

        if (!this.prixVente) {
            validationErrors.push(ValidationErrorsConst.PRIX_VENTE);
        }

        return validationErrors;
    }

    hasAdresse(): boolean {
        return this.adresse !== Reference.NO_ADRESSE;
    }

    isNew(): boolean {
        return this.uuid === Reference.statuts.NEW;
    }

    setPrixM2(): void {
        this._prixM2 = this.prixVente && this.superficie && this.superficie > 0 ? this.prixVente / this.superficie : 0;
    }
}
