export default class EstimationNotestim {
    private _iframeUrl!: string;
    private _isInit = false;
    private _linkRapportMedia!: string;

    get iframeUrl(): string {
        return this._iframeUrl;
    }

    set iframeUrl(value: string) {
        this._iframeUrl = value;
        this._isInit = !!this.iframeUrl;
    }

    get isInit(): boolean {
        return this._isInit;
    }

    get linkRapportMedia(): string {
        return this._linkRapportMedia;
    }

    set linkRapportMedia(value: string) {
        this._linkRapportMedia = value;
    }
}
