<form #editForm="ngForm" class="tw-form-2col">
  @if (footerLoading) {
    <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>

    <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>

    <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
  } @else {
    @if (footer.enabled) {
      <div class="tw-form-2col-part">
        <div class="tw-form-left-col">
          <h3 class="tw-form-left-col-title">Mise en page</h3>

          <p class="tw-form-left-col-explain">
            Par défaut, <app-noteo-icon-text-noty/> s'occupe de tout.<br><br>Si le pied de page des documents éditables
            (mandat, avis de valeur, ...) n'est pas cohérent avec les couleurs de l'étude, vous pouvez le
            personnaliser.
          </p>
        </div>

        <div class="tw-form-right-col">
          @if (cTemplateFooters$ | async; as cTemplateFooters) {
            <div>
              <div class="tw-flex tw-pb-2 tw-text-sm">
                <label class="tw-block tw-text-gray-700">Choix du modèle de base</label>

                @if (!footer.isCustom()) {
                  <button class="tw-text-blue-500 hover:tw-text-blue-600 tw-ml-8 tw-underline" type="button"
                          (click)="selectTemplateFooterCustom()">Personnaliser le modèle</button>
                }
              </div>

              <app-form-select-image [model]="footer.code" (selected)="selectTemplateFooter($event)"
                                     [options]="{selectionAccepted$: footerCodeValidation$.bind(this)}"
                                     [list]="cTemplateFooters.results"/>
            </div>
          } @else {
            <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
          }
        </div>
      </div>
    } @else {
      <div class="tw-form-2col-part">
        <div class="tw-form-left-col">
          <h3 class="tw-form-left-col-title">Mise en page</h3>

          <p class="tw-form-left-col-explain">Par défaut, <app-noteo-icon-text-noty/> s'occupe de tout.</p>
        </div>

        <div class="tw-form-right-col">
          <p>
            Le pied de page des documents éditables (mandat, avis de valeur, ...) est personnalisé par Noteo suite à
            la demande de l'étude.<br><br>Si vous souhaitez pouvoir le modifier quand et comme vous le désirez, merci de
            <esk-contact type="support" class="tw-inline-block tw-lowercase"/>.
          </p>
        </div>
      </div>
    }

    @if (footer.isCustom()) {
      <div class="tw-form-2col-part">
        <div class="tw-form-left-col">
          <h3 class="tw-form-left-col-title">Personnalisation du pied de page</h3>

          <p class="tw-form-left-col-explain">
            Personnalisez la forme ainsi que le contenu de votre pied de page afin de respecter la charte graphique de
            l'étude.
          </p>
        </div>

        <div class="tw-form-right-col tw-space-y-8">
          <div>
            <label for="footer-height" class="tw-block tw-text-sm tw-text-gray-700">Hauteur</label>

            <div class="tw-input-currency tw-mt-1">
              <input [(ngModel)]="footer.customHeight" type="text" placeholder="Hauteur" min="0" name="footerHeight"
                     (input)="updateFooter()" pattern="[0-9]+" id="footer-height" class="tw-input-number">

              <span>mm</span>
            </div>
          </div>

          <editor [(ngModel)]="footer.customTemplate" [init]="editorOptions" [ngModelOptions]="{standalone: true}"
                  (ngModelChange)="updateFooter()" ngDefaultControl/>
        </div>
      </div>
    }

    @if (footer.enabled) {
      <div class="tw-form-2col-part">
        <div class="tw-form-left-col">
          <h3 class="tw-form-left-col-title">Aperçu du pied de page</h3>

          <p class="tw-form-left-col-explain">
            Visualisez le rendu réel du pied de page utilisé sur les documents éditables.
          </p>
        </div>

        <div class="tw-form-right-col">
          @if (footerPreview$ | appLoadObs | async; as footerPreview) {
            <div>
              @if (footerPreview.value) {
                <div #preview class="tw-mx-auto tw-border tw-border-b-transparent preview-bottom">
                  <app-iframe [options]="footerPreviewOptions" [src]="footerPreview.value" class="tw-block"/>
                </div>
              } @else {
                <p>
                  Saisissez dans l'éditeur le contenu de votre en-tête.

                  @if (footer422Error) {
                    <span class="tw-text-orange-300">
                      <br><br>
                      {{ 'Attention à ne pas utiliser "{{" dans l\'éditeur ce qui peut amener des erreurs techniques pour la visualisation et l\'utilisation du pied de page.' }}
                    </span>
                  }
                </p>
              }
            </div>
          } @else {
            <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
          }
        </div>
      </div>
    }
  }
</form>
