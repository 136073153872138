<div class="tw-space-y-2">
  <h4 class="tw-px-3 lg:tw-px-4">Bâtiment</h4>

  <div class="tw-bg-gray-100 tw-rounded-xl tw-space-y-4 tw-p-3 lg:tw-p-4">
    <ul class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-2 tw-text-sm tw-text-gray-700 tw-font-medium">
      @if (isDateConstructionEtatVisible) {
        <li
          [ngClass]="{'xl:tw-col-span-2': dossierBien.bien.anneeConstruction > 0 && dossierBien.bien.epoqueConstruction && dossierBien.bien.etat}">
          @if (dossierBien.bien.anneeConstruction > 0) {
            <span class="tw-text-gray-500 tw-text-xs tw-font-normal">Construit en</span>
            {{ dossierBien.bien.anneeConstruction }}

            @if (!dossierBien.bien.epoqueConstruction && dossierBien.bien.etat) {
              <span class="tw-text-gray-500 tw-text-xs tw-font-normal">, </span>
            }

            @if (dossierBien.bien.epoqueConstruction) {
              <span class="tw-text-gray-500 tw-text-xs tw-font-normal">
                ({{ dossierBien.bien.epoqueConstruction.label | lowercase }})

                @if (dossierBien.bien.etat) {
                  ,
                }
              </span>
            }
          } @else {
            @if (dossierBien.bien.epoqueConstruction) {
              <span class="tw-text-gray-500 tw-text-xs tw-font-normal">Construction</span>
              {{ dossierBien.bien.epoqueConstruction.label | lowercase }}

              @if (dossierBien.bien.etat) {
                <span class="tw-text-gray-500 tw-text-xs tw-font-normal">, </span>
              }
            }
          }

          @if (dossierBien.bien.etat) {
            @if (dossierBien.bien.anneeConstruction > 0 || dossierBien.bien.epoqueConstruction) {
              {{ dossierBien.bien.etat.label | lowercase }}
            } @else {
              {{ dossierBien.bien.etat.label }}
            }
          }
        </li>
      }

      @if (isNombreEtagesBatimentVisible) {
        <li>
          <span class="tw-text-gray-500 tw-text-xs tw-font-normal">Bâtiment de</span>
          {{ dossierBien.bien.nombreEtagesBatiment }} étage{{ dossierBien.bien.nombreEtagesBatiment > 1 ? 's' : '' }}
        </li>
      }

      @if (isMitoyenneteVisible) {
        <li>{{ dossierBien.bien.mitoyennete.label }}</li>
      }

      @if (isExpositionVisible) {
        <li>Exposition {{ dossierBien.bien.exposition.label }}</li>
      }

      @if (isChauffageVisible) {
        <li [ngClass]="{'xl:tw-col-span-2': !dossierBien.bien.aucunChauffage && dossierBien.bien.chauffages.length >= (2 - (dossierBien.bien.chauffageCollectif ? 1 : 0))}">
          @if (dossierBien.bien.aucunChauffage) {
            Aucun chauffage
          } @else {
            Chauffage{{ dossierBien.bien.chauffages.length > 1 ? 's' : '' }}

            @if (dossierBien.bien.chauffageCollectif) {
              collectif{{ dossierBien.bien.chauffages.length > 1 ? 's' : '' }}
            }

            @for (chauffage of dossierBien.bien.chauffages; track chauffage; let last = $last) {
              {{ chauffage.label }}{{ last ? '' : ',' }}
            }
          }
        </li>
      }

      @if (isMateriauxConstructionVisible) {
        <li [ngClass]="{'xl:tw-col-span-2': dossierBien.bien.materiauxConstruction.length >= 2}">
          Matériau{{ dossierBien.bien.materiauxConstruction.length > 1 ? 'x' : '' }}
          <span class="tw-text-gray-500 tw-text-xs tw-font-normal">de</span> construction
          <span class="tw-text-gray-500 tw-text-xs tw-font-normal">en</span>

          @for (materiauConstruction of dossierBien.bien.materiauxConstruction; track materiauConstruction; let last = $last) {
            {{ materiauConstruction.label }}{{ last ? '' : ',' }}
          }
        </li>
      }

      @if (isCharpentesVisible) {
        <li>
          Charpente
          <span class="tw-text-gray-500 tw-text-xs tw-font-normal">en</span>

          @for (charpente of dossierBien.bien.charpentes; track charpente; let last = $last) {
            {{ charpente.label }}{{ last ? '' : ',' }}
          }
        </li>
      }

      @if (isMateriauxCouvertureVisible) {
        <li [ngClass]="{'xl:tw-col-span-2': dossierBien.bien.materiauxCouverture.length >= 2}">
          Matériau{{ dossierBien.bien.materiauxCouverture.length > 1 ? 'x' : '' }}
          <span class="tw-text-gray-500 tw-text-xs tw-font-normal">de</span> toiture
          <span class="tw-text-gray-500 tw-text-xs tw-font-normal">en</span>

          @for (materiauCouverture of dossierBien.bien.materiauxCouverture; track materiauCouverture; let last = $last) {
            {{ materiauCouverture.label }}{{ last ? '' : ',' }}
          }
        </li>
      }

      @if (isIsolationsVisible) {
        <li [ngClass]="{'xl:tw-col-span-2': dossierBien.bien.isolations.length >= 2}">
          Isolation
          <span class="tw-text-gray-500 tw-text-xs tw-font-normal">en</span>

          @for (isolation of dossierBien.bien.isolations; track isolation; let last = $last) {
            {{ isolation.label }}{{ last ? '' : ',' }}
          }
        </li>
      }

      @if (isHuisseriesVisible) {
        <li>
          Huisserie{{ dossierBien.bien.huisseries.length > 1 ? 's' : '' }}
          <span class="tw-text-gray-500 tw-text-xs tw-font-normal">en</span>

          @for (huisserie of dossierBien.bien.huisseries; track huisserie; let last = $last) {
            {{ huisserie.label }}{{ last ? '' : ',' }}
          }
        </li>
      }

      @if (isVoletsVisible) {
        <li>
          Volet{{ dossierBien.bien.volets.length > 1 ? 's' : '' }}
          <span class="tw-text-gray-500 tw-text-xs tw-font-normal">en</span>

          @for (volet of dossierBien.bien.volets; track volet; let last = $last) {
            {{ volet.label }}{{ last ? '' : ',' }}
          }
        </li>
      }
    </ul>

    @if (isCommentsBatimentVisible) {
      <div class="tw-text-sm tw-text-gray-700">{{ dossierBien.bien.commentsBatiment }}</div>
    }
  </div>
</div>
