import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgManager, NgPersonManager} from '@legacy/app/managers/managers';
import {NgReport} from '@legacy/app/managers/ressources';
import {NgReportParticipantsCollection} from '@legacy/app/managers/collections';

export default function getManagersReportParticipant(module: IModule): void {
    (angular => {
        'use strict';

        module.factory('ReportParticipantManager', manager);

        /**
         * Manager email dossier
         *
         * @param BaseManager
         * @param PersonManager
         * @returns {ReportParticipantManager}
         */
        manager.$inject = ['BaseManager', 'PersonManager'];
        function manager(baseManager: NgManager, personManager: NgPersonManager) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('report-participants.cget', {path: '/reports/{reportId}/participants', method: 'GET'});
            baseManager.addRoute('report-participants.insert', {
                path: '/reports/{reportId}/participants',
                method: 'POST'
            });

            /**
             * ReportDossierManager object
             *
             * @constructor
             */
            angular.extend(ReportParticipantManager.prototype, baseManager.__proto__);
            ReportParticipantManager.prototype.eskManager = {
                prefixRoute: 'report-participants',
                collectionName: 'items'
            };
            function ReportParticipantManager() {
            }

            /**
             * Returns the promise to fetch object
             *
             * @param reportId
             * @returns {Promise}
             */
            ReportParticipantManager.prototype.getAll = function (reportId: number) {
                return baseManager.getAll.call(this, {reportId: reportId}).then(
                    participants => participants as NgReportParticipantsCollection
                ).then(participants => {
                    participants.collection.forEach(participant => participant._esk.defaultRouteParams!.reportId = reportId);

                    return participants;
                });
            };

            /**
             * Create a ReportParticipant object
             *
             * @param data
             * @param reportId
             * @returns manager.ReportParticipant object
             */
            ReportParticipantManager.prototype.create = function (data: unknown, reportId: number) {
                if (angular.isArray(data)) {
                    const participants = [];

                    for (let i = 0; i < data.length; i++) {
                        // @ts-ignore
                        participants.push(new ReportParticipant(reportId, data[i]));
                    }

                    return participants;
                }

                // @ts-ignore
                return new ReportParticipant(reportId, data);
            };

            /**
             * ReportParticipant object
             *
             * @param reportId
             * @param data
             * @constructor
             */
            angular.extend(ReportParticipant.prototype, classResource.prototype);
            ReportParticipant.prototype.eskManager = {prefixRoute: 'report-participants'};
            function ReportParticipant(this: NgReport, reportId: number, data: unknown) {
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id, reportId: reportId};
            }

            ReportParticipant.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);
                this.person = personManager.create(this.person);

                return this;
            };

            ReportParticipant.prototype.sanitize = function () {
                const sanitizeObject = classResource.prototype.sanitize.call(this);

                sanitizeObject.personId = this.person.id;
                delete sanitizeObject.person;

                return sanitizeObject;
            };

            // @ts-ignore
            return new ReportParticipantManager();
        }
    })(angularJS);
}
