@if (etude$ | appLoadObs | async; as etude) {
  @if (etude.value) {
    <button type="button" (click)="openSlideOver(etude.value)"
            class="tw-btn-tertiary-info tw-h-full tw-w-full tw-rounded-full tw-py-0 tw-px-1 tw-text-inherit tw-font-inherit tw-text-size-inherit">
      <div class="tw-truncate">{{ etude.value.raisonSociale }} ({{ etude.value.crpcen }})</div>
    </button>
  }
} @else {
  <app-loader class="tw-block tw-w-40 tw-max-w-full tw-ml-1"/>
}
