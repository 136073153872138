<div class="modal-without-overflow">
  <div class="tw-mb-5">
    <h3 class="tw-flex">
      <!-- 20px pour 28px -->
      Importer depuis <app-noteo-logo-notestim class="tw-h-[20px]"/>
    </h3>
  </div>

  @if (errorMessage) {
    <div class="tw-flex tw-flex-col tw-space-y-4">
      <div class="tw-bg-orange-50 tw-text-orange-600 tw-p-4 tw-rounded-lg">
        <p class="tw-font-semibold">
          La référence <span class="tw-font-bold">{{ notestimBien.libelle }}</span> ne peut pas être importée.
        </p>

        <p class="tw-font-semibold">
          <!-- 11px pour 16px -->
          Veuillez corriger l'erreur suivante dans <app-noteo-logo-notestim class="tw-h-[11px]"/> avant de recommencer.
        </p>

        <p>- {{ errorMessage }}</p>
      </div>

      <div class="tw-flex tw-justify-center">
        <button type="button" class="tw-btn-primary-default" (click)="closeModal()">Fermer</button>
      </div>
    </div>
  } @else {
    <div class="tw-space-y-8">
      <div class="tw-color-gray-500 tw-font-semibold tw-leading-4">
        <p>La référence <span class="tw-font-bold">{{ notestimBien.libelle }}</span> est en cours d'importation.</p>

        <p>
          L'import peut prendre quelques instants afin de récupérer toutes les informations depuis
          <!-- 11px pour 16px -->
          <app-noteo-logo-notestim class="tw-h-[11px]"/>.
        </p>
      </div>

      <div class="tw-flex tw-justify-center"><fa-icon [icon]="'spinner'" animation="spin" size="2x"/></div>

      <p class="tw-text-xs">La fenêtre se fermera automatiquement à la fin de l'import.</p>
    </div>
  }
</div>
