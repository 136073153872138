<div [@openClose]="isOpen" [hidden]="!isOpen" class="tw-absolute tw-z-[9999] tw-inset-0 tw-overflow-y-auto"
     aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="tw-flex tw-items-start tw-justify-center tw-relative tw-min-h-screen tw-text-center sm:tw-block sm:tw-p-0">
    <div class="tw-fixed tw-h-full tw-w-full tw-bg-gray-500 tw-bg-opacity-50 tw-transition-opacity tw-backdrop-blur-sm"
         (click)="close()" aria-hidden="true"></div>

    <div class="tw-absolute tw-left-1/2 -tw-translate-x-1/2 tw-inline-block sm:tw-max-w-[90%] tw-bg-white tw-rounded-lg tw-shadow-xl tw-text-left tw-transition-all"
         [ngClass]="{'tw-top-1 sm:tw-top-4 lg:tw-top-8': options.align === 'top',
                      'tw-top-1/2 -tw-translate-y-1/2': options.align === 'middle',
                      'tw-bottom-1 sm:tw-bottom-4 lg:tw-bottom-8': options.align === 'bottom',
                      'tw-p-4 sm:tw-p-6': options.withPadding}"
         [@openCloseModal]="isOpen">
      @if (options.allowClose) {
        <div class="tw-absolute tw-top-0 tw-right-0 tw-pt-4 tw-pr-4">
          @if (options.button === 'cross') {
            <button class="tw-bg-white tw-h-8 tw-w-8 tw-text-gray-400 hover:tw-text-gray-500" aria-label="Close"
                    (click)="close()" type="button">
              <span class="tw-text-2xl"><fa-icon [icon]="'times'"/></span>
            </button>
          }

          @if (options.button === 'ok') {
            <button class="tw-btn-secondary-info" aria-label="Close" (click)="close()" type="button">OK</button>
          }
        </div>
      }

      <ng-template #modalContent/>
    </div>
  </div>
</div>
