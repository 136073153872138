<div class="tw-second-nav">
  <div class="tw-second-nav-menus">
    <a [routerLink]="'/app/annuaires/etudes'" class="tw-text-gray-700 tw-flex tw-items-center tw-gap-4">
      <fa-icon [icon]="'arrow-left'" size="xl" class="tw-opacity-80"/>

      <span class="tw-text-lg">Retour à la liste d'études</span>
    </a>
  </div>

  @if (etude$ | async; as etude) {
    <div class="tw-second-nav-content">
      <div class="tw-bg-white">
        <div class="tw-max-w-5xl tw-mx-auto tw-bg-gray-50">
          <div class="tw-h-full tw-max-w-4xl tw-mx-auto tw-pb-8 sm:tw-pt-4">
            <div class="tw-flex tw-flex-col">
              @if (currentEtude$ | async; as currentEtude) {
                <div class="tw-relative tw-overflow-hidden sm:tw-rounded-lg">
                  <app-image-preview [options]="{editButtonLabel: etude.id === currentEtude.id ? 'Modifier votre bannière' : undefined!, noLinkButtonLabel: etude.id === currentEtude.id ? 'Ajouter une bannière' : undefined!, noLinkContainerIcon: ['fal', 'building-columns'], priority: true}"
                                     [link]="etude.linkBanner" (action)="goToConfigurationEtude()"/>
                </div>
              } @else {
                <app-loader [classHeight]="'tw-aspect-cinema'" class="tw-block"/>
              }

              <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-4 tw-items-center tw-z-10">
                <div class="tw-min-w-[10rem] tw-w-1/4 tw-aspect-square md:tw-mx-4 tw-rounded-lg tw-shadow tw-bg-white tw-p-2 -tw-mt-[15%]">
                  <div class="tw-relative tw-h-full tw-w-full">
                    <img class="tw-object-contain" [ngSrc]="etude.linkLogo" fill>
                  </div>
                </div>

                @if (sitePrincipal$ | async; as sitePrincipal) {
                  <div class="tw-flex tw-flex-col tw-flex-1 tw-w-full tw-items-center md:tw-items-start tw-p-4">
                    <div class="tw-text-lg tw-font-bold tw-text-gray-900">{{ etude.raisonSociale }}</div>

                    <app-site-card [etude]="etude" [site]="sitePrincipal" class="tw-block tw-w-full"/>
                  </div>
                } @else {
                  <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
                }
              </div>
            </div>

            <div class="tw-grid tw-grid-flow-row-dense tw-auto-rows-fr tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
              <div class="tw-hidden tw-col-span-2 tw-row-span-2 lg:tw-block">
                <app-etude-map [etude]="etude" class="tw-block tw-overflow-hidden tw-h-full tw-rounded-lg"/>
              </div>

              @if (cSitesAnnexes$ | async; as cSites) {
                @for (site of cSites.results; track site; let idx = $index) {
                  <div class="lg:tw-col-span-2 tw-bg-gray-100 tw-rounded-lg tw-p-4"
                       [ngClass]="{'lg:tw-col-start-3 lg:tw-row-start-3': cSites.results.length === 3 && idx === 2}">
                    <app-site-card [etude]="etude" [site]="site"/>
                  </div>
                }

                @if (cSites.total % 2 !== 0) {
                  <div class="lg:tw-col-span-2"></div>
                }
              } @else {
                <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
              }

              @if (cVentes$ | async; as cVentes) {
                @if (cVentes.total > 0) {
                  <div class="tw-col-span-2 md:tw-col-span-1 lg:tw-col-span-2">
                    <div class="tw-block sm:tw-flex md:tw-block tw-items-center tw-gap-8 tw-rounded-lg tw-bg-white tw-shadow tw-p-6 tw-space-y-6 sm:tw-space-y-0 md:tw-space-y-6">
                      <app-dossiers-biens-stacked-images [cDossierBiens]="cVentes" class="tw-block tw-h-12 tw-text-sm"
                                                         [options]="{labelSingular: 'bien en vente', labelPlural: 'biens en vente'}"/>

                      <div class="tw-text-center">
                        <a class="tw-btn-secondary-default" [routerLink]="'/app/ventes/search'"
                           [state]="{extraParams: {etudeId: etude.id}}">
                          <fa-icon [icon]="['fal', 'house']"/> <span>Biens à vendre</span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="tw-col-span-2 md:tw-col-span-1 lg:tw-col-span-2">
                    @if (cVentesCompromis$ | async; as cVentesCompromis) {
                      @if (cVentesCompromis.total > 0) {
                        <div class="tw-block sm:tw-flex md:tw-block tw-items-center tw-gap-8 tw-rounded-lg tw-bg-white tw-shadow tw-p-6 tw-space-y-6 sm:tw-space-y-0 md:tw-space-y-6">
                          <app-dossiers-biens-stacked-images [cDossierBiens]="cVentesCompromis"
                                                             class="tw-block tw-h-12 tw-text-sm"
                                                             [options]="{labelSingular: 'bien en compromis', labelPlural: 'biens en compromis'}"/>

                          <div class="tw-text-center">
                            <a class="tw-btn-secondary-default" [routerLink]="'/app/ventes/search'"
                               [state]="{extraParams: {etudeId: etude.id, statuts: [VENTE_STATUT_COMPROMIS]}}">
                              <fa-icon [icon]="['far', 'bookmark']"/> <span>Biens en compromis</span>
                            </a>
                          </div>
                        </div>
                      }
                    } @else {
                      <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
                    }
                  </div>
                }
              } @else {
                <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-col-span-2 md:tw-col-span-1 lg:tw-col-span-2"/>

                <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-col-span-2 md:tw-col-span-1 lg:tw-col-span-2"/>
              }

              @if (cUsers$ | async; as cUsers) {
                @for (user of cUsers.results; track user) {
                  <app-user-card [user]="user" class="tw-row-span-2"/>
                }
              } @else {
                <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  } @else {
    <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
  }
</div>
