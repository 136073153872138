@if (cEstimationReferences$ | async; as cEstimationReferences) {
  <div class="tw-overflow-hidden">
    <table class="tw-table-head-separate-body">
      <thead>
      <tr>
        <th><div>Adresse</div></th>

        <th class="tw-hidden md:tw-table-cell tw-w-32 2xl:tw-w-36">
          <div>
            <span class="2xl:tw-hidden">Date vente</span> <span class="tw-hidden 2xl:tw-inline">Date de vente</span>
          </div>
        </th>

        <th class="tw-hidden lg:tw-table-cell"><div>Époque</div></th>

        <th class="tw-hidden sm:tw-table-cell tw-w-32 2xl:tw-w-36 tw-text-right">
          <div>
            <span class="2xl:tw-hidden">{{ superficieLabel.replace('Surface', 'Surf.') }}</span>
            <span class="tw-hidden 2xl:tw-inline">{{ superficieLabel }}</span>
          </div>
        </th>

        <th class="tw-hidden 2xl:tw-table-cell tw-w-40 tw-text-right">
          <div>
            <span class="2xl:tw-hidden">Surf. cadastrale</span>
            <span class="tw-hidden 2xl:tw-inline">Surface cadastrale</span>
          </div>
        </th>

        <th class="tw-w-32 2xl:tw-w-36 tw-text-right"><div>Prix de vente</div></th>

        <th class="tw-hidden lg:tw-table-cell tw-w-28 2xl:tw-w-32 tw-text-right">
          <div>
            <span class="2xl:tw-hidden">Prix m²</span>
            <span class="tw-hidden 2xl:tw-inline">Prix au m²</span>
          </div>
        </th>

        <th class="tw-w-16 lg:tw-w-20 print:tw-hidden"><div><div class="tw-h-5"></div></div></th>
      </tr>
      </thead>

      <tbody>
        @for (estimationReference of cEstimationReferences.results; track estimationReference) {
          <tr [appCollectionSortable]="[AppEstimationReferencesListName, cEstimationReferences.results, estimationReference.uuid]"
              [appCollectionSortableOptions]="{name: AppEstimationReferencesListName, tHeadWithDiv: true}">
            <td>
              {{ estimationReference.hasAdresse() ? estimationReference.adresse : '' }}
              <app-commune-convivial-name [linkCommune]="estimationReference.linkCommune"
                                          class="tw-block tw-text-gray-400"/>
            </td>

            <td class="tw-hidden md:tw-table-cell">{{ estimationReference.dateVente | date }}</td>

            <td class="tw-hidden lg:tw-table-cell">
              <app-dictionary-item-convivial-name [dictionaryName]="DICTIONARY_NAME_CONSTRUCTION_EPOQUES"
                                                  [id]="estimationReference.epoqueId"/>
            </td>

            <td class="tw-hidden sm:tw-table-cell tw-text-right">
              {{ estimationReference.superficie | textCurrency:'m²' }}
            </td>

            <td class="tw-hidden 2xl:tw-table-cell tw-text-right">
              {{ estimationReference.surfaceCadastrale | textCurrency:'m²' }}
            </td>

            <td class="tw-text-right">{{ estimationReference.prixVente | textCurrency }}</td>

            <td class="tw-hidden lg:tw-table-cell tw-text-right">
              {{ estimationReference.prixM2 > 0 ? (estimationReference.prixM2 | textCurrency) : null }}
            </td>

            <td class="tw-table-head-separate-body-actions">
              <button class="tw-btn-secondary-info tw-bg-transparent tw-shadow-none tw-ring-0 tw-w-10" type="button"
                      #buttonElement (click)="clickOnEstimationReference(buttonElement, estimationReference)">
                <fa-icon [icon]="'ellipsis-v'"/>
              </button>
            </td>
          </tr>
        }
      </tbody>
    </table>

    <app-load-more [collection$]="cEstimationReferences$" (loadMore)="getMore(cEstimationReferences)"
                   [loading]="pendingGetMore"/>
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
}
