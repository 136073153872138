import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {EstimationReferenceFactory} from '@models/estimations/estimation/references/reference/estimation-reference.factory';
import {ICEstimationReferencesApi} from '@models/estimations/estimation/references/collection/estimation-references.collection.interfaces';
import CEstimationReferences from '@models/estimations/estimation/references/collection/estimation-references.collection.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
    EstimationReferencesApiService
} from '@models/estimations/estimation/references/estimation-references.api.service';
import Estimation from '@models/estimations/estimation/estimation.model';

@Injectable({providedIn: 'root'})
export class CEstimationReferencesFactory {
    private _estimationReferenceFactory = inject(EstimationReferenceFactory);
    private _estimationReferencesApiService = inject(EstimationReferencesApiService);

    create(cEstimationReferencesApi: ICEstimationReferencesApi): CEstimationReferences {
        const cEstimationReferences = new CEstimationReferences();

        cEstimationReferences.links = new Links(cEstimationReferencesApi._links);
        cEstimationReferences.page = cEstimationReferencesApi.page;
        cEstimationReferences.pages = cEstimationReferencesApi.pages;
        cEstimationReferences.perPage = cEstimationReferencesApi.limit;
        cEstimationReferences.total = cEstimationReferencesApi.total;
        if (cEstimationReferencesApi._embedded?.items) {
            cEstimationReferences.results = cEstimationReferencesApi._embedded.items.filter(estimationReferenceApi => estimationReferenceApi)
                .map(estimationReferenceApi => this._estimationReferenceFactory.create(estimationReferenceApi));
        }

        return cEstimationReferences;
    }

    get$(estimation: Estimation): Observable<CEstimationReferences> {
        return this._estimationReferencesApiService.getCollection$(estimation.id.toString()).pipe(map(cReferencesApi => this.create(cReferencesApi)));
    }

    getNext$(cEstimationReferences: CEstimationReferences): Observable<CEstimationReferences> {
        return this._estimationReferencesApiService.getCollectionByLink$(cEstimationReferences.links.next)
            .pipe(map(cEstimationReferencesApi => this.create(cEstimationReferencesApi)));
    }
}
