@if (etude$ | async; as etude) {
  <div class="modal-without-overflow tw-flex tw-flex-col">
    <div class="tw-p-4 tw-pr-[5.5rem] tw-border-b tw-border-gray-300">
      <form>
        <app-form-search-input (searchedText)="searchContacts($event)" [loading]="displaySearchLoader"
                               [options]="{hasFocus: true, placeholder: 'Rechercher ou créer un contact'}"
                               [model]="searchText"/>
      </form>
    </div>

    <div class="tw-flex-1 tw-overflow-auto">
      <div class="tw-m-6 tw-mt-8">
        @if (searchText) {
          <div class="md:tw-w-[650px]">
            @if (cContactsNoty$ | async; as cContactsNoty) {
              @if (cContactsNoty.total > 0) {
                <app-contacts-source [cContacts$]="cContactsNoty$" [keyword]="searchText" [selectedContacts]="contacts"
                                     [source]="sources.LOCAL" (added)="addFromNoty($event)"/>

                <hr class="tw-my-4 sm:tw-my-8"/>
              }
            } @else {
              <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-6"/>

              <hr class="tw-my-4 sm:tw-my-8"/>
            }

            @if (etude.settingsGenapi.inotEnabled) {
              @if (cContactsInot$ | async; as cContactsInot) {
                @if (cContactsInot.total > 0) {
                  <app-contacts-source [cContacts$]="cContactsInot$" [keyword]="searchText"
                                       [enableAdding]="enableImportContactInot" [source]="sources.INOT"
                                       [selectedContacts]="contacts" (added)="importFromInot$($event)"/>

                  <hr class="tw-my-4 sm:tw-my-8"/>
                } @else {
                  @if (errorCContactsInot) {
                    <app-inot-request-error [httpErrorResponse]="errorCContactsInot"
                                            (action)="launchCContactsInotSource.next()"/>

                    <hr class="tw-my-4 sm:tw-my-8"/>
                  }
                }
              } @else {
                <app-inot-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-6"/>

                <hr class="tw-my-4 sm:tw-my-8"/>
              }

              @if (cEtudeDossiersInot$ | async; as cEtudeDossiersInot) {
                @if (cEtudeDossiersInot.total > 0) {
                  <app-etude-dossiers [cEtudeDossiers$]="cEtudeDossiersInot$" [keyword]="searchText"
                                      [selectedContacts]="contacts" [enableAddingContact]="enableImportContactInot"
                                      (addedContact)="importFromInot$($event)"/>

                  <hr class="tw-my-4 sm:tw-my-8"/>
                } @else {
                  @if (errorCEtudeDossiersInot) {
                    <app-inot-request-error [httpErrorResponse]="errorCEtudeDossiersInot" [options]="{type: 'dossiers'}"
                                            (action)="launchCEtudeDossiersInotSource.next()"/>

                    <hr class="tw-my-4 sm:tw-my-8"/>
                  }
                }
              } @else {
                <app-inot-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-6" [options]="{type: 'dossiers'}"/>

                <hr class="tw-my-4 sm:tw-my-8"/>
              }
            } @else {
              <ng-container [ngTemplateOutlet]="advertisementInot"/>
            }
          </div>
        } @else {
          <div class="tw-my-4 sm:tw-my-8 tw-color-gray-500 tw-font-semibold">
            La recherche dans <app-noteo-icon-text-noty/> s'effectuera sur les champs énumérés ci-dessous :

            <!-- Supprimer les ! dans le cas de la suppression de `ul[class*=tw-]` dans style.scss -->
            <ul class="!tw-list-disc !tw-list-inside">
              <li>Nom(s) et prénom(s) / Raison sociale</li>

              <li>Téléphone(s) et mobile(s)</li>

              <li>Adresse(s) email</li>

              <li>Adresse postale</li>
            </ul>

            <hr class="tw-my-4 sm:tw-my-8"/>

            @if (etude.settingsGenapi.inotEnabled) {
              La recherche dans <app-noteo-icon-text-inot/> s'effectuera sur les champs énumérés ci-dessous :

              <!-- Supprimer les ! dans le cas de la suppression de `ul[class*=tw-]` dans style.scss -->
              <ul class="!tw-list-disc !tw-list-inside">
                <li>Personne : Nom(s), prénom(s), civilité</li>

                <li>Entreprise : Raison sociale, siret / siren, l'immatriculation RCS et le nom de contact</li>

                <li>Dossier : Nom et nature</li>

                <li>Mots clés et références/numéros dans<app-noteo-icon-text-inot/></li>
              </ul>

              <hr class="tw-my-4 sm:tw-my-8"/>
            } @else {
              <ng-container [ngTemplateOutlet]="advertisementInot"/>
            }
          </div>
        }

        <div class="tw-flex tw-flex-col tw-items-center tw-space-y-4 tw-color-gray-500 tw-font-medium tw-my-4 sm:tw-my-8">
          @if (searchText) {
            <span>Vous ne trouvez pas <span class="tw-font-bold">{{ searchText }}</span> ?</span>
          } @else {
            <span>
              Votre contact est peut-être déjà dans votre portefeuille<br>
              Pensez à le rechercher pour éviter de créer un doublon.
            </span>
          }

          <button type="button" class="tw-btn-primary-info tw-mx-auto disabled:tw-waiting"
                  [disabled]="!enableImportContactInot" (click)="contactCreation()">Créer un nouveau contact</button>
        </div>

        <ng-template #advertisementInot>
          <div class="tw-flex tw-mb-4">
            <span class="tw-w-6 tw-h-6 tw-inline-block"><fa-icon [icon]="'rss'" size="xl"/></span>
            <span class="tw-font-bold tw-text-base tw-ml-4">Passerelles</span>
          </div>

          <div class="tw-w-full tw-text-center tw-text-base tw-font-semibold">
            Découvrez comment récupérer vos contacts depuis <app-noteo-icon-text-inot/>

            <br/>

            <app-noteo-contact [module]="MODULE_INOT" class="tw-block tw-mt-4"/>
          </div>

          <hr class="tw-my-4 sm:tw-my-8"/>
        </ng-template>
      </div>
    </div>
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-10'" class="tw-block margin-top-10"/>
}
