import {inject, Injectable} from '@angular/core';
import ReportParticipant from '@models/reports/report/participants/participant/report-participant.model';
import {IReportParticipantApi} from '@models/reports/report/participants/participant/report-participant.interfaces';
import {ReportParticipantsApiService} from '@models/reports/report/participants/report-participants.api.service';
import {Observable} from 'rxjs';
import Report from '@models/reports/report/report.model';
import {NgReportParticipant} from '@legacy/app/managers/ressources';

@Injectable({providedIn: 'root'})
export class ReportParticipantFactory {
    private _reportParticipantsApiService = inject(ReportParticipantsApiService);

    create(reportParticipantApi: IReportParticipantApi): ReportParticipant {
        return new ReportParticipant(reportParticipantApi.uuid, reportParticipantApi.id);
    }

    delete$(report: Report, reportParticipant: ReportParticipant): Observable<void> {
        return this._reportParticipantsApiService.delete$(report.id.toString(), reportParticipant.id.toString());
    }

    ngCreate(ngReportParticipant: NgReportParticipant): ReportParticipant {
        return new ReportParticipant(ngReportParticipant.uuid, ngReportParticipant.id);
    }
}
