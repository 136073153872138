import {Component, inject, OnInit} from '@angular/core';
import {UserService} from '@models/users/user/user.service';
import {Observable, ReplaySubject} from 'rxjs';
import User from '@models/users/user/user.model';
import {NgEstimationEvaluationComponent} from '@shared/angularJS/up-ng/estimations/estimation-evaluation.component';
import {AsyncPipe} from '@angular/common';
import {LoadModule} from '@shared/load/load.module';
import {AppNoteoContactComponent} from '@shared/noteo/contact/noteo-contact.component';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgEstimationManager} from '@legacy/app/managers/managers';
import EstimationNotestim from '@models/estimations/estimation/notestim/estimation-notestim.model';
import {EstimationFactory} from '@models/estimations/estimation/estimation.factory';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {EstimationNotestimService} from '@models/estimations/estimation/notestim/estimation-notestim.service';
import Estimation from '@models/estimations/estimation/estimation.model';
import {ModalService} from '@shared/modal/modal.service';
import Etude from '@models/etudes/etude/etude.model';
import {IframeModule} from '@shared/iframe/iframe.module';
import {RouterOutlet} from '@angular/router';
import {AppNoteoLogoNotestimComponent} from '@shared/noteo/logo/notestim/noteo-logo.notestim.component';
import {AppNoteoIconTextNotyComponent} from '@shared/noteo/icon-text/noty/noteo-icon-text.noty.component';
import {AppNoteoIconTextComponent} from '@shared/noteo/icon-text/noteo-icon-text.component';

@Component({
    // @todo Supprimer l'import de *Module pour préférer seulement *Component quand ceux-ci seront standalone
    imports: [
        AppNoteoContactComponent,
        AppNoteoIconTextComponent,
        AppNoteoIconTextNotyComponent,
        AppNoteoLogoNotestimComponent,
        AsyncPipe,
        IframeModule,
        LoadModule,
        NgEstimationEvaluationComponent,
        RouterOutlet,
    ],
    selector: 'layout-estimation-evaluation-root',
    standalone: true,
    templateUrl: 'layout-estimation-evaluation-root.component.html',
})
export class AppLayoutEstimationEvaluationRootComponent implements OnInit {
    private _estimationFactory = inject(EstimationFactory);
    private _estimationNotestimService = inject(EstimationNotestimService);
    private _modalService = inject(ModalService);
    private _ngInjectorService = inject(NgInjectorService);
    private _userService = inject(UserService);
    private _estimation$!: Observable<Estimation>;
    private _estimationNotestimSource = new ReplaySubject<EstimationNotestim>(1);
    private _estimationNotestim$ = this._estimationNotestimSource.asObservable();

    // Supprimer les injections des anciens manager
    private get ngEstimationManager(): NgEstimationManager {
        return this._ngInjectorService.getService<NgEstimationManager>('EstimationManager');
    }

    get currentUser$(): Observable<User> {
        return this._userService.last$;
    }

    get estimation$(): Observable<Estimation> {
        return this._estimation$;
    }

    get estimationNotestim$(): Observable<EstimationNotestim> {
        return this._estimationNotestim$;
    }

    get LOGO_NOTESTIM_WHITE(): string {
        return Etude.enablableModules.NOTESTIM.srcImageWhite;
    }

    get MODULE_NOTESTIM(): string {
        return AppNoteoContactComponent.modules.NOTESTIM;
    }

    ngOnInit(): void {
        this._estimation$ = this.ngEstimationManager.current$.pipe(
            filter(ngEstimation => !!ngEstimation),
            map(ngEstimation => this._estimationFactory.ngCreate(ngEstimation)),
        );
        this.estimation$.pipe(
            switchMap(estimation => this._estimationNotestimService.getOne$(estimation)),
            take(1),
        ).subscribe(estimationNotestim => this._estimationNotestimSource.next(estimationNotestim));
    }

    createNotestim(estimation: Estimation): void {
        this._estimationNotestimSource.next(undefined!);
        this._estimationNotestimService.save$(estimation).pipe(take(1))
            .subscribe(estimationNotestim => this._estimationNotestimSource.next(estimationNotestim));
    }

    upgradeToNotestim(estimation: Estimation): void {
        this._modalService.openConfirmation$({
            buttonConfirmationLabel: 'Mettre à niveau',
            comments: 'Le passage à Notestim empêchera tout retour à l\'évaluation Noty actuelle, notamment l\'accès aux références sélectionnées, au rapport, etc.',
            question: 'Souhaitez-vous évaluer votre bien avec Notestim ?',
            title: 'Évaluer avec Notestim',
            status: ModalService.status.WARNING,
        }).pipe(take(1)).subscribe(confirm => {
            if (confirm) {
                this.createNotestim(estimation);
            }
        });
    }
}
