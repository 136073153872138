import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Pipe({name: 'appLoadObs', standalone: true})
export class AppLoadObsPipe implements PipeTransform {
    transform<T>(obs$: Observable<T>, returnUndefinedInValue = true): Observable<{ value: T }> {
        return obs$.pipe(map(value => !returnUndefinedInValue && value === undefined ? undefined! : ({value})));
    }
}
