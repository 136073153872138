import {inject, Injectable} from '@angular/core';
import ReportDossier from '@models/reports/report/dossiers/dossier/report-dossier.model';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import {LocationFactory} from '@models/locations/location/location.factory';
import {EstimationFactory} from '@models/estimations/estimation/estimation.factory';
import {IReportDossierApi} from '@models/reports/report/dossiers/dossier/report-dossier.interfaces';
import {ReportDossiersApiService} from '@models/reports/report/dossiers/report-dossiers.api.service';
import {Observable} from 'rxjs';
import Report from '@models/reports/report/report.model';
import {NgReportDossier} from '@legacy/app/managers/ressources';

@Injectable({providedIn: 'root'})
export class ReportDossierFactory {
    private _demandeurFactory = inject(DemandeurFactory);
    private _estimationFactory = inject(EstimationFactory);
    private _locationFactory = inject(LocationFactory);
    private _reportDossiersApiService = inject(ReportDossiersApiService);
    private _venteFactory = inject(VenteFactory);

    create(reportDossierApi: IReportDossierApi): ReportDossier {
        const reportDossier = new ReportDossier(reportDossierApi.uuid, reportDossierApi.id);

        reportDossier.type = reportDossierApi.type;
        if (reportDossierApi._embedded) {
            if (reportDossierApi._embedded.demandeur) {
                reportDossier.demandeur = this._demandeurFactory.create(reportDossierApi._embedded.demandeur);
            }

            if (reportDossierApi._embedded.estimation) {
                reportDossier.estimation = this._estimationFactory.create(reportDossierApi._embedded.estimation);
            }

            if (reportDossierApi._embedded.location) {
                reportDossier.location = this._locationFactory.create(reportDossierApi._embedded.location);
            }

            if (reportDossierApi._embedded.vente) {
                reportDossier.vente = this._venteFactory.create(reportDossierApi._embedded.vente);
            }
        }

        return reportDossier;
    }

    delete$(report: Report, reportDossier: ReportDossier): Observable<void> {
        return this._reportDossiersApiService.delete$(report.id.toString(), reportDossier.id.toString());
    }

    ngCreate(ngReportDossier: NgReportDossier): ReportDossier {
        return new ReportDossier(ngReportDossier.uuid, ngReportDossier.id);
    }
}
