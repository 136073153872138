import {Component, inject, OnInit} from '@angular/core';
import {Observable, of, ReplaySubject, switchMap} from 'rxjs';
import {take} from 'rxjs/operators';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {EtudeFactory} from '@models/etudes/etude/etude.factory';
import Etude from '@models/etudes/etude/etude.model';
import Site from '@models/sites/site/site.model';
import {CSitesService} from '@models/sites/collection/sites.collection.service';
import {CVentesFactory} from '@models/ventes/collection/ventes.collection.factory';
import CVentes from '@models/ventes/collection/ventes.collection.model';
import {IEtudeSlideOverData} from '@features/etudes/etudes.interfaces';
import {AsyncPipe, NgOptimizedImage} from '@angular/common';
import {LoadModule} from '@shared/load/load.module';
import {UsersModule} from '@features/users/users.module';
import {ImageModule} from '@shared/image/image.module';
import {SitesModule} from '@features/sites/sites.module';
import {DossiersModule} from '@features/dossiers/dossiers.module';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {RouterLink} from '@angular/router';

@Component({
    imports: [
        AsyncPipe,
        DossiersModule,
        FaIconComponent,
        ImageModule,
        LoadModule,
        NgOptimizedImage,
        RouterLink,
        SitesModule,
        UsersModule,
    ],
    selector: 'etude-slide-over',
    standalone: true,
    templateUrl: 'etude.slide-over.component.html',
})
export class EtudeSlideOverComponent implements IDynamicComponent, OnInit {
    private _cSitesService = inject(CSitesService);
    private _cVentesFactory = inject(CVentesFactory);
    private _etudeFactory = inject(EtudeFactory);
    private _cVentes$!: Observable<CVentes>;
    private _etudeSource = new ReplaySubject<Etude>(1);
    private _etude$ = this._etudeSource.asObservable();
    private _sitePrincipal$!: Observable<Site>;

    get cVentes$(): Observable<CVentes> {
        return this._cVentes$;
    }

    set data(dynamicComponentData: IEtudeSlideOverData) {
        let etude$ = of(dynamicComponentData.etude!);

        if (dynamicComponentData.link) {
            etude$ = this._etudeFactory.getByLink$(dynamicComponentData.link);
        }

        etude$.pipe(take(1)).subscribe(etude => this._etudeSource.next(etude));
    }

    get etude$(): Observable<Etude> {
        return this._etude$;
    }

    get sitePrincipal$(): Observable<Site> {
        return this._sitePrincipal$;
    }

    ngOnInit(): void {
        this._cVentes$ = this._etude$.pipe(switchMap(etude => this._cVentesFactory.getByLink$(etude.linkVentes)));
        this._sitePrincipal$ = this._etude$.pipe(switchMap(etude => this._cSitesService.getSitePrincipal$(etude.linkSites)));
    }
}
