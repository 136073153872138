import {Component, Input} from '@angular/core';
import {INoteoIconTextOptions} from '@shared/noteo/noteo.interfaces';
import {AppNoteoIconTextComponent} from '@shared/noteo/icon-text/noteo-icon-text.component';

// @todo Utiliser, comme pour Notestim, un composant AppNoteoLogoNotyComponent
@Component({
    imports: [AppNoteoIconTextComponent],
    selector: 'app-noteo-icon-text-noty',
    standalone: true,
    templateUrl: 'noteo-icon-text.noty.component.html',
})
export class AppNoteoIconTextNotyComponent {
    static readonly initNoteoIconTextNotyOptions: INoteoIconTextOptions = {
        loading: 'lazy',
        verticalAlignClass: 'tw-align-[-1px]',
    };
    static readonly label = 'Noty';
    static readonly srcImage = '/assets/images/septeo/logo.svg';
    private _label = AppNoteoIconTextNotyComponent.label;
    private _options: INoteoIconTextOptions = {...AppNoteoIconTextNotyComponent.initNoteoIconTextNotyOptions};
    private _srcImage = AppNoteoIconTextNotyComponent.srcImage;

    get label(): string {
        return this._label;
    }

    @Input()
    set label(value: string) {
        this._label = value;
    }

    get options(): INoteoIconTextOptions {
        return this._options;
    }

    @Input()
    set options(value: INoteoIconTextOptions) {
        this._options = {...AppNoteoIconTextNotyComponent.initNoteoIconTextNotyOptions, ...value};
    }

    get srcImage(): string {
        return this._srcImage;
    }

    @Input()
    set srcImage(value: string) {
        this._srcImage = value;
    }
}
