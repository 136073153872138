import {Component, inject, Input, OnInit} from '@angular/core';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';

@Component({selector: 'app-dictionary-item-convivial-name', standalone: true, template: '{{dictionaryItem.label}}'})
export class AppDictionaryItemConvivialNameComponent implements OnInit {
    private _dictionaryItemFactory = inject(DictionaryItemFactory);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _dictionaryItem!: DictionaryItem;
    private _id!: number;
    private _dictionaryName!: string;

    get dictionaryItem(): DictionaryItem {
        return this._dictionaryItem;
    }

    @Input()
    set dictionaryName(value: string) {
        this._dictionaryName = value;
    }

    @Input()
    set id(value: number) {
        this._id = value;
    }

    ngOnInit(): void {
        this._dictionaryItem = this._dictionaryItemService.getById(this._dictionaryName, this._id) ?? this._dictionaryItemFactory.createVirgin();
    }
}
