import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {
    NgDemandeurManager, NgEstimationManager, NgLocationManager, NgManager, NgVenteManager
} from '@legacy/app/managers/managers';
import {NgDemandeur, NgEstimation, NgLocation, NgReportDossier, NgVente} from '@legacy/app/managers/ressources';
import {NgReportDossiersCollection} from '@legacy/app/managers/collections';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';

export default function getManagersReportDossier(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('ReportDossierManager', manager);

        /**
         * Manager report dossier
         *
         * @param BaseManager
         * @param DemandeurManager
         * @param EstimationManager
         * @param LocationManager
         * @param VenteManager
         */
        manager.$inject = ['BaseManager', 'DemandeurManager', 'EstimationManager', 'LocationManager', 'VenteManager'];
        function manager(baseManager: NgManager,
                         demandeurManager: NgDemandeurManager,
                         estimationManager: NgEstimationManager,
                         locationManager: NgLocationManager,
                         venteManager: NgVenteManager) {
            const classResource = baseManager.getClass();

            /**
             * Routing
             */
            baseManager.addRoute('report-dossiers.cget', {path: '/reports/{reportId}/dossiers', method: 'GET'});
            baseManager.addRoute('report-dossiers.insert', {path: '/reports/{reportId}/dossiers', method: 'POST'});

            /**
             * ReportDossierManager object
             *
             * @constructor
             */
            angular.extend(ReportDossierManager.prototype, baseManager.__proto__);
            ReportDossierManager.prototype.eskManager = {prefixRoute: 'report-dossiers', collectionName: 'items'};
            function ReportDossierManager() {
            }

            /**
             * Returns the promise to fetch object
             *
             * @param reportId
             * @returns {Promise}
             */
            ReportDossierManager.prototype.getAll = function (reportId: number) {
                return baseManager.getAll.call(this, {reportId})
                    .then(ngCReportDossiers => ngCReportDossiers as NgReportDossiersCollection)
                    .then(ngCReportDossiers => {
                        ngCReportDossiers.collection = ngCReportDossiers.collection.map(ngReportDossier => {
                            ngReportDossier._esk.defaultRouteParams!.reportId = reportId;
                            if (ngReportDossier.type === DossierTypesConst.DEMANDEUR) {
                                ngReportDossier.demandeur = demandeurManager.create(ngReportDossier.demandeur) as NgDemandeur;
                            } else if (ngReportDossier.type === DossierTypesConst.ESTIMATION) {
                                ngReportDossier.estimation = estimationManager.create(ngReportDossier.estimation) as NgEstimation;
                            } else if (ngReportDossier.type === DossierTypesConst.LOCATION) {
                                ngReportDossier.location = locationManager.create(ngReportDossier.location) as NgLocation;
                            } else if (ngReportDossier.type === DossierTypesConst.VENTE) {
                                ngReportDossier.vente = venteManager.create(ngReportDossier.vente) as NgVente;
                            }

                            return ngReportDossier;
                        });

                        return ngCReportDossiers;
                    });
            };

            /**
             * Create a ReportDossier object
             *
             * @param data
             * @param reportId
             * @returns manager.ReportDossier object
             */
            ReportDossierManager.prototype.create = function (data: unknown, reportId: number) {
                if (angular.isArray(data)) {
                    var dossiers = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        dossiers.push(new ReportDossier(reportId, data[i]));
                    }

                    return dossiers;
                }

                // @ts-ignore
                return new ReportDossier(reportId, data);
            };

            /**
             * ReportDossier object
             *
             * @param reportId
             * @param data
             * @constructor
             */
            angular.extend(ReportDossier.prototype, classResource.prototype);
            ReportDossier.prototype.eskManager = {prefixRoute: 'report-dossiers'};
            function ReportDossier(this: NgReportDossier, reportId: number, data: unknown) {
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id, reportId};
            }

            /**
             * Sanitize object ReportDossier before send to API
             *
             * @returns sanitizeObject
             */
            ReportDossier.prototype.sanitize = function () {
                const sanitizeObject = classResource.prototype.sanitize.call(this);

                delete sanitizeObject.dossier;

                return sanitizeObject;
            };

            // @ts-ignore
            return new ReportDossierManager();
        }
    })(angularJS);
}
