import {inject, Injectable} from "@angular/core";
import {EstimationNotestimApiService} from '@models/estimations/estimation/notestim/estimation-notestim.api.service';
import EstimationNotestim from '@models/estimations/estimation/notestim/estimation-notestim.model';
import {IEstimationNotestimApi} from '@models/estimations/estimation/notestim/estimation-notestim.interfaces';
import Estimation from '@models/estimations/estimation/estimation.model';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpOptions} from '@core/api/api.interfaces';

@Injectable({providedIn: 'root'})
export class EstimationNotestimFactory {
    private _estimationNotestimsApiService = inject(EstimationNotestimApiService);

    create(estimationNotestimApi: IEstimationNotestimApi): EstimationNotestim {
        const estimationNotestim = this.createVirgin();

        estimationNotestim.iframeUrl = estimationNotestimApi.iframeUrl;
        if (estimationNotestimApi._links?.rapportMedia) {
            estimationNotestim.linkRapportMedia = estimationNotestimApi._links.rapportMedia.href;
        }

        return estimationNotestim;
    }

    createVirgin(): EstimationNotestim {
        return new EstimationNotestim();
    }

    get$(estimation: Estimation, httpOptions?: HttpOptions): Observable<EstimationNotestim> {
        return this._estimationNotestimsApiService.get$(estimation.uuid, httpOptions).pipe(map(estimationNotestimApi => this.create(estimationNotestimApi)));
    }

    save$(estimation: Estimation, httpOptions?: HttpOptions): Observable<EstimationNotestim> {
        return this._estimationNotestimsApiService.save$(estimation.uuid, httpOptions).pipe(map(estimationNotestimApi => this.create(estimationNotestimApi)));
    }
}
