import {Component, Input} from '@angular/core';
import {NoteoStatutColorConst, NoteoStatutColorType} from '@shared/noteo/noteo.constants';
import {NgClass} from '@angular/common';

@Component({
    imports: [NgClass],
    selector: 'app-noteo-statut',
    standalone: true,
    templateUrl: 'noteo-statut.component.html',
})
export class AppNoteoStatutComponent {
    private _color!: NoteoStatutColorType;
    private _label!: string;

    get STATUT_COLORS(): typeof NoteoStatutColorConst {
        return NoteoStatutColorConst;
    }

    get color(): NoteoStatutColorType {
        return this._color;
    }

    @Input({required: true})
    set color(value: NoteoStatutColorType) {
        this._color = value;
    }

    get label(): string {
        return this._label;
    }

    @Input({required: true})
    set label(value: string) {
        this._label = value;
    }
}
