@if (rapportMedia$ | async; as rapportMedia) {
  @if (rapportMedia.linkDownload) {
    <div class="tw-h-[50rem] tw-w-full lg:tw-w-[calc(21cm_+_8rem)] tw-mx-auto">
      <app-pdf [pdfPath]="rapportMedia.linkDownload"/>
    </div>
  } @else {
    <div class="tw-flex tw-flex-col tw-items-center tw-gap-8 tw-p-16">
      <app-noteo-logo-notestim class="tw-max-w-[400px] tw-w-full"/>

      <h2 class="tw-text-septeo-blue-900 tw-font-normal">
        <!-- 23px pour 33px -->
        La création du rapport avec <app-noteo-logo-notestim class="tw-h-[23px]"/> n'est pas arrivée à son terme.
      </h2>
    </div>
  }

  <div class="tw-w-full tw-py-8 tw-text-center">
    <p class="tw-text-base tw-text-gray-500">
      Pour modifier votre rapport, retournez dans la partie
      <a [routerLink]="['../../evaluation']">"Évaluation" de votre bien en estimation</a> et modifier le avec
      <!-- 14px pour 20px -->
      <app-noteo-logo-notestim class="tw-h-[14px]"/>.
    </p>
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block"/>
}
