import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable, switchMap} from 'rxjs';
import {
    IEstimationReferenceApi
} from '@models/estimations/estimation/references/reference/estimation-reference.interfaces';
import EstimationReference from '@models/estimations/estimation/references/reference/estimation-reference.model';
import {
    ICEstimationReferencesApi
} from '@models/estimations/estimation/references/collection/estimation-references.collection.interfaces';

@Injectable({providedIn: 'root'})
export class EstimationReferencesApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(estimationId: string, uuid: string): Observable<void> {
        return this._modelApiService.delete$(`/estimations/${estimationId}/references/${uuid}`);
    }

    get$(estimationUuid: string, uuid: string): Observable<IEstimationReferenceApi> {
        return this.getByLink$(`/estimations/${estimationUuid}/references/${uuid}`);
    }

    getByLink$(link: string): Observable<IEstimationReferenceApi> {
        return this._modelApiService.get$(link);
    }

    getCollection$(estimationId: string): Observable<ICEstimationReferencesApi> {
        return this.getCollectionByLink$(`/estimations/${estimationId}/references`);
    }

    getCollectionByLink$(link: string): Observable<ICEstimationReferencesApi> {
        return this._modelApiService.getCollection$(link);
    }

    save$(estimationId: string, uuid: string, estimationReferenceApi: IEstimationReferenceApi): Observable<IEstimationReferenceApi> {
        if (uuid === EstimationReference.statuts.NEW) {
            return this._modelApiService.post$(`/estimations/${estimationId}/references`, estimationReferenceApi);
        }

        return this._modelApiService.put$(`/estimations/${estimationId}/references/${uuid}`, estimationReferenceApi)
            .pipe(switchMap(_ => this.get$(estimationId, uuid)));
    }

    updateRank$(estimationId: string, uuid: string, rank: number): Observable<void> {
        return this._modelApiService.patch$(`/estimations/${estimationId}/references/${uuid}/rank`, {rank});
    }
}
