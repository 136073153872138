import {NgModule} from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {CommonModule} from '@angular/common';
import {
    DCEstimationRapportErrorCityscanLocationComponent
} from '@features/estimations/estimation/rapport/error-cityscan-location/estimation-rapport.error-cityscan-location.component';
import {
    EstimationRapportDropdownComponent
} from '@features/estimations/estimation/rapport/dropdown/estimation-rapport.dropdown.component';
import {
    EstimationRapportMissingDataComponent
} from '@features/estimations/estimation/rapport/missing-data/estimation-rapport.missing-data.component';
import {
    DCEstimationReferenceEditComponent
} from '@features/estimations/estimation/references/reference/edit/estimation.reference-edit.component';
import {CommunesModule} from '@features/communes/communes.module';
import {DictionariesModule} from '@features/dictionaries/dictionaries.module';
import {RouterModule} from '@angular/router';
import {EstimationActionsMainComponent} from '@features/estimations/estimation/actions/estimation.actions-main.component';
import {
    EstimationActionsSelectionComponent
} from '@features/estimations/estimation/actions/estimation.actions-selection.component';
import {
    AppEstimationOnsaleReferencesListComponent
} from '@features/estimations/estimation/onsale-references/list/estimation.onsale-references-list.component';
import {
    EstimationOnsaleReferenceDropdownComponent
} from '@features/estimations/estimation/onsale-references/onsale-reference/dropdown/estimation.onsale-reference.dropdown.component';
import {
    EstimationOnsaleReferencePhotoComponent
} from '@features/estimations/estimation/onsale-references/onsale-reference/photo/estimation.onsale-reference.photo.component';
import {
    DCEstimationOnsaleReferenceSlideOverComponent
} from '@features/estimations/estimation/onsale-references/onsale-reference/slide-over/estimation.onsale-reference.slide-over.component';
import {
    EstimationReferenceDropdownComponent
} from '@features/estimations/estimation/references/reference/dropdown/estimation-reference.dropdown.component';

@NgModule({
    declarations: [
        AppEstimationOnsaleReferencesListComponent,
        DCEstimationOnsaleReferenceSlideOverComponent,
        DCEstimationRapportErrorCityscanLocationComponent,
        DCEstimationReferenceEditComponent,
        EstimationActionsMainComponent,
        EstimationActionsSelectionComponent,
        EstimationOnsaleReferenceDropdownComponent,
        EstimationOnsaleReferencePhotoComponent,
        EstimationRapportDropdownComponent,
        EstimationRapportMissingDataComponent,
        EstimationReferenceDropdownComponent,
    ],
    exports: [EstimationRapportMissingDataComponent],
    imports: [CommonModule, CommunesModule, DictionariesModule, RouterModule, SharedModule],
})
export class EstimationsModule {
}
