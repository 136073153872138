import {NgModule} from '@angular/core';
import {AppFormErrorComponent} from '@shared/form/error/form-error.component';
import {AppFormErrorDirective} from '@shared/form/error/form-error.directive';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {AppFormSelectInputComponent} from '@shared/form/select/input/form.select-input.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {ProvideParentFormDirective} from '@shared/form/provide-parent-form.directive';
import {
    AppFormTextareaAutoResizeDirective
} from '@shared/form/textarea-auto-resize/form.textarea-auto-resize.directive';
import {AppFormRadioComponent} from '@shared/form/radio/form.radio.component';
import {AppFormRadioYnnComponent} from '@shared/form/radio/ynn/form.radio-ynn.component';
import {AppFormSelectDateComponent} from '@shared/form/select/date/form.select-date.component';
import {AppFormSwitchComponent} from '@shared/form/switch/form.switch.component';
import {AppFormSelectImageComponent} from '@shared/form/select/image/form.select-image.component';
import {FormEmailValidatorDirective} from '@shared/form/validators/form.email.validator';
import {FormPhoneValidatorDirective} from '@shared/form/validators/form.phone.validator';
import {AppFormSelectButtonComponent} from '@shared/form/select/button/select-button.component';
import {AppFormSearchInputComponent} from '@shared/form/search-input/form-search-input.component';
import {AppFormSelectDatetimeComponent} from '@shared/form/select/datetime/form.select-datetime.component';
import {LoadModule} from '@shared/load/load.module';
import {AppFormFilePickerComponent} from '@shared/form/file/picker/form-file-picker.component';
import {AppFormFileDropperDirective} from '@shared/form/file/dropper/form-file.dropper.directive';
import {AppFormCheckboxListComponent} from '@shared/form/checkbox/list/form.checkbox-list.component';
import {AppFormRangeSliderComponent} from '@shared/form/range-slider/form.range-slider.component';
import {NgxSliderModule} from 'ngx-slider-v2';
import {FaModule} from '@shared/fontawesome/fa.module';
import {SearchModule} from '@shared/search/search.module';

@NgModule({
    declarations: [
        AppFormCheckboxListComponent,
        AppFormErrorComponent,
        AppFormErrorDirective,
        AppFormFileDropperDirective,
        AppFormFilePickerComponent,
        AppFormRadioComponent,
        AppFormRadioYnnComponent,
        AppFormRangeSliderComponent,
        AppFormSearchInputComponent,
        AppFormSelectButtonComponent,
        AppFormSelectDateComponent,
        AppFormSelectDatetimeComponent,
        AppFormSelectImageComponent,
        AppFormSelectInputComponent,
        AppFormSwitchComponent,
        AppFormTextareaAutoResizeDirective,
        FormEmailValidatorDirective,
        FormPhoneValidatorDirective,
        ProvideParentFormDirective,
    ],
    exports: [
        AppFormCheckboxListComponent,
        AppFormErrorDirective,
        AppFormFileDropperDirective,
        AppFormFilePickerComponent,
        AppFormRadioComponent,
        AppFormRadioYnnComponent,
        AppFormRangeSliderComponent,
        AppFormSearchInputComponent,
        AppFormSelectButtonComponent,
        AppFormSelectDateComponent,
        AppFormSelectDatetimeComponent,
        AppFormSelectImageComponent,
        AppFormSelectInputComponent,
        AppFormSwitchComponent,
        AppFormTextareaAutoResizeDirective,
        FormEmailValidatorDirective,
        FormPhoneValidatorDirective,
        ProvideParentFormDirective,
    ],
    imports: [
        CommonModule,
        FaModule,
        FormsModule,
        LoadModule,
        NgOptimizedImage,
        NgSelectModule,
        NgxSliderModule,
        SearchModule,
    ],
})
export class AppFormModule {
}
