import {NgModule} from '@angular/core';
import {ModalComponent} from '@shared/modal/modal.component';
import {ModalConfirmationComponent} from '@shared/modal/confirmation/modal.confirmation.component';
import {ModalStatusIconComponent} from '@shared/modal/status-icon/modal.status-icon.component';

@NgModule({
    imports: [ModalComponent, ModalConfirmationComponent, ModalStatusIconComponent],
    exports: [ModalComponent, ModalStatusIconComponent],
})
export class ModalModule {
}
