import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ModelApiService} from '@models/model.api.service';
import {ICReportDossiersApi} from '@models/reports/report/dossiers/collection/report-dossiers.collection.interfaces';

@Injectable({providedIn: 'root'})
export class ReportDossiersApiService {
    private _modelApiService = inject(ModelApiService);

    delete$(reportId: string, id: string): Observable<void> {
        return this._modelApiService.delete$(`/reports/${reportId}/dossiers/${id}`);
    }

    getCollection$(reportId: string): Observable<ICReportDossiersApi> {
        return this.getCollectionByLink$(`/reports/${reportId}/dossiers`);
    }

    getCollectionByLink$(link: string): Observable<ICReportDossiersApi> {
        return this._modelApiService.getCollection$(link);
    }
}
