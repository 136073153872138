import {Component, Input} from '@angular/core';
import Etude from '@models/etudes/etude/etude.model';
import {INoteoIconTextOptions} from '@shared/noteo/noteo.interfaces';
import {AppNoteoIconTextComponent} from '@shared/noteo/icon-text/noteo-icon-text.component';

// @todo Utiliser, comme pour Notestim, un composant AppNoteoInotComponent
@Component({
    imports: [AppNoteoIconTextComponent],
    selector: 'app-noteo-icon-text-inot',
    standalone: true,
    templateUrl: 'noteo-icon-text.inot.component.html',
})
export class AppNoteoIconTextInotComponent {
    static readonly initNoteoIconTextInotOptions: INoteoIconTextOptions = {loading: 'lazy'};
    private _label = Etude.enablableModules.INOT.label;
    private _options: INoteoIconTextOptions = {...AppNoteoIconTextInotComponent.initNoteoIconTextInotOptions};
    private _srcImage = Etude.enablableModules.INOT.srcImage;

    get label(): string {
        return this._label;
    }

    @Input()
    set label(value: string) {
        this._label = value;
    }

    get options(): INoteoIconTextOptions {
        return this._options;
    }

    @Input()
    set options(value: INoteoIconTextOptions) {
        this._options = {...AppNoteoIconTextInotComponent.initNoteoIconTextInotOptions, ...value};
    }

    get srcImage(): string {
        return this._srcImage;
    }

    @Input()
    set srcImage(value: string) {
        this._srcImage = value;
    }
}
