import {Component, HostBinding, inject} from '@angular/core';
import {NavigationService} from '@shared/navigation/navigation.service';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';

@Component({
    imports: [FaIconComponent],
    selector: 'app-navigation-toggle',
    standalone: true,
    templateUrl: 'navigation-toggle.component.html',
})
export class AppNavigationToggleComponent {
    // @todo Passer ces class dans ClassCSSConst
    static readonly INIT_CLASS = 'tw-block md:tw-hidden tw-h-full tw-mr-1';
    private _navigationService = inject(NavigationService);

    @HostBinding('class') class = AppNavigationToggleComponent.INIT_CLASS;

    toggle(): void {
        this._navigationService.toggle();
    }
}
