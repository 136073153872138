import angularJS from '@shared/angularJS/global.ng';
import {IModule, IPromise, IQService} from 'angular';
import {
    NgDemandeurManager, NgEstimationManager, NgLocationManager, NgManager, NgReportAttachmentManager,
    NgReportDossierManager, NgReportParticipantManager, NgUtilsManager, NgVenteManager
} from '@legacy/app/managers/managers';
import {NgFilter, NgReport} from '@legacy/app/managers/ressources';
import DateFormat from '@shared/date/date.format';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';

export default function getManagersReport(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('ReportManager', manager);

        /**
         * Manager report
         *
         * @param $q
         * @param BaseManager
         * @param ReportDossierManager
         * @param ReportParticipantManager
         * @param ReportAttachmentManager
         * @param VenteManager
         * @param LocationManager
         * @param DemandeurManager
         * @param UtilsManager
         * @param EstimationManager
         * @returns {ReportManager}
         */
        manager.$inject = ['$q', 'BaseManager', 'ReportDossierManager', 'ReportParticipantManager', 'ReportAttachmentManager', 'VenteManager', 'LocationManager', 'DemandeurManager', 'UtilsManager', 'EstimationManager'];
        function manager($q: IQService,
                         baseManager: NgManager,
                         reportDossierManager: NgReportDossierManager,
                         reportParticipantManager: NgReportParticipantManager,
                         reportAttachmentManager: NgReportAttachmentManager,
                         venteManager: NgVenteManager,
                         locationManager: NgLocationManager,
                         demandeurManager: NgDemandeurManager,
                         utilsManager: NgUtilsManager,
                         estimationManager: NgEstimationManager) {
            let classResource = baseManager.getClass<NgReport>();

            /**
             * Routing
             */
            baseManager.addRoute('reports.cget-etude', {path: '/etude/reports', method: 'GET'});
            baseManager.addRoute('reports.get', {path: '/reports/{id}', method: 'GET'});
            baseManager.addRoute('reports.insert', {path: '/user/reports', method: 'POST'});
            baseManager.addRoute('reports.edit', {path: '/reports/{id}', method: 'PUT'});

            /**
             * ReportManager object
             *
             * @constructor
             */
            angular.extend(ReportManager.prototype, baseManager.__proto__);
            ReportManager.prototype.eskManager = {prefixRoute: 'reports', collectionName: 'items'};
            function ReportManager() {
            }

            /**
             * Returns the promise to fetch collection of filtered ventes from etude
             *
             * @param filter
             * @param params
             * @returns {Promise}
             */
            ReportManager.prototype.getAllEtudeFiltered = function (filter: NgFilter, params: unknown) {
                return this.get('cget-etude', angular.extend(filter.getParamsRequest(), params));
            };

            /**
             * Returns the promise to fetch object
             *
             * @param resourceId
             * @returns {Promise}
             */
            ReportManager.prototype.getOneById = function (resourceId: number) {
                return this.get('get', {id: resourceId}).then((report: NgReport) => reportDossierManager.getAll(report.id)
                    .then(reportDossiers => report.dossiers = reportDossiers.collection)
                    .then(() => report));
            };

            /**
             * Create a Report object
             *
             * @param data
             * @returns manager.Report object || Array of manager.Report objects
             */
            ReportManager.prototype.create = function (data: unknown) {
                if (angular.isArray(data)) {
                    var reports = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        reports.push(new Report(data[i]));
                    }

                    return reports;
                }

                // @ts-ignore
                return new Report(data);
            };

            /**
             * Report object
             *
             * @param data
             * @constructor
             */
            angular.extend(Report.prototype, classResource.prototype);
            Report.prototype.eskManager = {prefixRoute: 'reports'};

            function Report(this: NgReport, data: unknown) {
                this.extend(data);
                this._esk.defaultRouteParams = {id: this.id};
            }

            /**
             * Extend the existing Report with new data
             *
             * @param data
             * @returns {Report}
             */
            Report.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                if (angular.isArray(this.dossiers)) {
                    this.dossiers = reportDossierManager.create(this.dossiers, this.id);
                } else {
                    this.dossiers = [];
                }

                if (angular.isArray(this.participants)) {
                    this.participants = reportParticipantManager.create(this.participants, this.id);
                } else {
                    this.participants = [];
                }

                if (angular.isArray(this.attachments)) {
                    this.attachments = reportAttachmentManager.create(this.attachments, this.id);
                } else {
                    this.attachments = [];
                }

                if (angular.isString(this.date)) {
                    this.date = DateFormat.toDate(this.date);
                } else {
                    this.date = DateFormat.toDate();
                }

                return this;
            };

            /**
             * Get data on attached dossier
             *
             * @returns {Promise}
             */
            Report.prototype.getDossiers = function () {
                const promises = [] as IPromise<void>[];

                angular.forEach(this.dossiers, function (reportDossier) {
                    if (reportDossier.type === DossierTypesConst.VENTE) {
                        promises.push(venteManager.getOneById(reportDossier.typeId).then(vente => {
                            reportDossier.dossier = vente;
                        }));
                    } else if (reportDossier.type === DossierTypesConst.LOCATION) {
                        promises.push(locationManager.getOneById(reportDossier.typeId).then(location => {
                            reportDossier.dossier = location;
                        }));
                    } else if (reportDossier.type === DossierTypesConst.ESTIMATION) {
                        promises.push(estimationManager.getOneById(reportDossier.typeId).then(estimation => {
                            reportDossier.dossier = estimation;
                        }));
                    } else if (reportDossier.type === DossierTypesConst.DEMANDEUR) {
                        promises.push(demandeurManager.getOneById(reportDossier.typeId).then(demandeur => {
                            reportDossier.dossier = demandeur;
                        }));
                    }
                });

                return $q.all(promises);
            };

            /**
             * Save Resource
             *
             * @returns {Promise}
             */
            Report.prototype.save = function () {
                const self = this;
                var dossiers = self.dossiers;
                var participants = self.participants;
                var attachments = self.attachments;

                return classResource.prototype.save.call(self).then(function () {
                    const promises = [] as IPromise<void>[];

                    self.dossiers = dossiers;
                    self.participants = participants;
                    self.attachments = attachments;
                    angular.forEach(self.dossiers, function (dossier) {
                        if (!dossier._esk.persist) {
                            dossier._esk.defaultRouteParams.reportId = self.id;
                            promises.push(dossier.save());
                        }
                    });
                    angular.forEach(self.participants, function (participant) {
                        if (!participant._esk.persist) {
                            participant._esk.defaultRouteParams.reportId = self.id;
                            promises.push(participant.save());
                        }
                    });
                    angular.forEach(self.attachments, function (attachment) {
                        if (!attachment._esk.persist) {
                            attachment._esk.defaultRouteParams.reportId = self.id;
                            promises.push(attachment.save());
                        }
                    });

                    return $q.all(promises).then(function () {
                        return self;
                    });
                });
            };

            /**
             * Sanitize object Report before send to API
             *
             * @returns {Object}
             */
            Report.prototype.sanitize = function () {
                var sanitizeObject = classResource.prototype.sanitize.call(this);

                utilsManager.flattenIdForAttribute(sanitizeObject, 'theme');
                sanitizeObject.date = DateFormat.toAPI(this.date);
                delete sanitizeObject.dossiers;
                delete sanitizeObject.participants;
                delete sanitizeObject.attachments;

                return sanitizeObject;
            };

            // @ts-ignore
            return new ReportManager();
        }
    })(angularJS);
}
