@if (ngEstimation) {
  <div appProvideParentForm>
    <!-- Groupe de contacts -->
    <fieldset>
      <legend>Propriétaires</legend>

      <app-dossier-contacts-group-edit [link$]="contactsGroupLink$" (changed)="onChangeContactsGroup($event)"/>
    </fieldset>

    <!-- Localisation -->
    <fieldset><legend>Localisation</legend> <app-bien-edit-localisation [ngBienDossier]="ngEstimation"/></fieldset>

    <!-- Description -->
    <fieldset><legend>Description</legend><app-bien-edit-description [ngBienDossier]="ngEstimation"/></fieldset>

    <!-- Valeur -->
    <fieldset>
      <legend>Valeur</legend>

      @if (ngEstimation.type.code === ESTIMATION_TYPE_AVIS_VALEUR) {
        <div class="row">
          <!-- Type valeur vénale -->
          <div class="col-sm-3">
            <label>Type de valeur vénale</label>

            <div>
              <app-dictionary-radio [model]="typeValeurVenale" [name]="ESTIMATION_VALEUR_VENALE_TYPES"
                                    (selected)="onSelectTypeValeurVenale($event)"/>
            </div>
          </div>

          <!-- Valeur vénale -->
          <div class="col-sm-9">
            @if (ngEstimation.typeValeurVenale.code === ESTIMATION_VALEUR_VENALE_TYPE_FOURCHETTE) {
              <div>
                <label for="valeur-venale-min">Valeur vénale *</label>

                <div>
                  <!-- Valeur vénale minimum -->
                  <div class="pull-left form-group" [appFormError]="valeurVenaleMin">
                    <div class="input-group input-group-number input-group-currency">
                      <input type="text" id="valeur-venale-min" pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$"
                             [required]="ngEstimation.statut === ESTIMATION_BROUILLON ? null! : 'true'"
                             name="valeurVenaleMin" [(ngModel)]="ngEstimation.valeurVenaleMin"
                             placeholder="Minimum" class="form-control text-right" #valeurVenaleMin="ngModel">

                      <span class="input-group-addon">€</span>
                    </div>
                  </div>

                  <!-- Valeur vénale maximum -->
                  <div class="pull-left form-group" [appFormError]="valeurVenaleMax">
                    <div class="input-group input-group-number input-group-currency">
                      <input type="text" id="valeur-venale-max" pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$"
                             [required]="ngEstimation.statut === ESTIMATION_BROUILLON ? null! : 'true'"
                             name="valeurVenaleMax" [(ngModel)]="ngEstimation.valeurVenaleMax"
                             placeholder="Maximum" class="form-control text-right" #valeurVenaleMax="ngModel">

                      <span class="input-group-addon">€</span>
                    </div>
                  </div>
                </div>
              </div>
            }

            @if (ngEstimation.typeValeurVenale.code === ESTIMATION_VALEUR_VENALE_TYPE_SIMPLE) {
              <div>
                <label for="valeur-venale">Valeur vénale *</label>

                <div>
                  <!-- Valeur vénale -->
                  <div class="pull-left form-group" [appFormError]="valeurVenale">
                    <div class="input-group input-group-number input-group-currency">
                      <input type="text" id="valeur-venale" pattern="^(?=.*[1-9])\d*(?:.\d*)?\s*$" name="valeurVenale"
                             [required]="ngEstimation.statut === ESTIMATION_BROUILLON ? null! : 'true'"
                             class="form-control text-right" #valeurVenale="ngModel"
                             [(ngModel)]="ngEstimation.valeurVenale">

                      <span class="input-group-addon">€</span>
                    </div>
                  </div>

                  <!-- Pourcentage de la valeur vénale -->
                  <div class="pull-left form-group" [appFormError]="pourcentageValeurVenale">
                    <div class="input-group input-group-number input-group-currency">
                      <span class="input-group-addon addon-left"><fa-icon [icon]="'plus-minus'" size="2x"/></span>

                      <input name="pourcentageValeurVenale" pattern="^\d*$"
                             [(ngModel)]="ngEstimation.pourcentageValeurVenale"
                             type="text" class="form-control text-right" #pourcentageValeurVenale="ngModel">

                      <span class="input-group-addon">%</span>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      }

      @if (ngEstimation.type.code !== ESTIMATION_TYPE_AVIS_VALEUR) {
        <div class="text-large">
          <p>
            Votre bien en estimation étant une <span class="text-bold">{{ ngEstimation.type.label | lowercase }}</span>,
            vous pourrez gérer sa valeur vénale dans la partie

            @if (ngEstimation.id) {
              <a [routerLink]="'/app/estimations/' + ngEstimation.id + '/evaluation'">Évaluation</a>
            } @else {
              "Évaluation" une fois le premier enregistrement effectué
            }.
          </p>

          @if (!!ngEstimation.valeurVenale) {
            <p>
              La valeur vénale de votre bien en <span class="text-bold">{{ ngEstimation.type.label | lowercase }}</span>
              est de <span class="text-bold">{{ ngEstimation.valeurVenale | textCurrency }}</span>.
            </p>
          }
        </div>
      }
    </fieldset>

    <!-- Dossier -->
    <fieldset><legend>Dossier</legend> <app-bien-edit-dossier [ngBienDossier]="ngEstimation"/></fieldset>
  </div>
}
