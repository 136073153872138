import {inject, Injectable} from '@angular/core';
import {from, Observable, of, ReplaySubject, switchMap} from 'rxjs';
import Estimation from '@models/estimations/estimation/estimation.model';
import {EstimationNotestimService} from '@models/estimations/estimation/notestim/estimation-notestim.service';
import {filter, map, take} from 'rxjs/operators';
import EstimationNotestim from '@models/estimations/estimation/notestim/estimation-notestim.model';
import {ActivatedRoute, Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class EstimationRapportService {
    static readonly ROUTING = {EDIT: 'edit', READ: 'read', WRITE: 'redaction'};
    static readonly STATUTS = {EDIT: 'EDIT', READ: 'READ', WRITE: 'WRITE'};
    private _estimationNotestimService = inject(EstimationNotestimService);
    private _router = inject(Router);
    private _estimation!: Estimation;
    private _estimationNotestimSource = new ReplaySubject<EstimationNotestim>(1);
    private _estimationNotestim$ = this._estimationNotestimSource.asObservable();
    private _statut$ = this._estimationNotestim$.pipe(
        filter(estimationNotestim => !!estimationNotestim),
        map(estimationNotestim => {
            if (estimationNotestim.isInit) {
                return EstimationRapportService.STATUTS.READ;
            }

            return this._estimation.rapportId ? EstimationRapportService.STATUTS.WRITE : EstimationRapportService.STATUTS.EDIT;
        }),
    );

    get estimationNotestim$(): Observable<EstimationNotestim> {
        return this._estimationNotestim$;
    }

    get statut$(): Observable<string> {
        return this._statut$;
    }

    init(estimation: Estimation): void {
        this._estimation = estimation;
        this._estimationNotestimSource.next(undefined!);
        this._estimationNotestimService.getOne$(estimation).pipe(take(1))
            .subscribe(estimationNotestim => this._estimationNotestimSource.next(estimationNotestim));
    }

    redirect$(currentUrl: string, activatedRoute: ActivatedRoute): Observable<boolean> {
        return this.statut$.pipe(
            take(1),
            switchMap(statut => {
                if (currentUrl.endsWith(EstimationRapportService.ROUTING[statut])) {
                    return of(false);
                }

                return from(this._router.navigateByUrl(this._router.createUrlTree(
                    [`../${EstimationRapportService.ROUTING[statut]}`],
                    {relativeTo: activatedRoute},
                ).toString()));
            }),
        );
    }
}
