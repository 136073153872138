import {AppDictionaryRadioComponent} from '@features/dictionaries/dictionary/radio/dictionary-radio.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppDictionarySelectComponent} from '@features/dictionaries/dictionary/select/dictionary-select.component';
import {AppDictionaryCheckboxComponent} from '@features/dictionaries/dictionary/checkbox/dictionary-checkbox.component';
import {SharedModule} from '@shared/shared.module';
import {
    AppDictionaryItemConvivialNameComponent
} from '@features/dictionaries/dictionary/items/item/convivial-name/dictionary-item.convivial-name.component';

@NgModule({
    declarations: [AppDictionaryCheckboxComponent, AppDictionaryRadioComponent, AppDictionarySelectComponent],
    exports: [
        AppDictionaryCheckboxComponent,
        AppDictionaryItemConvivialNameComponent,
        AppDictionaryRadioComponent,
        AppDictionarySelectComponent,
    ],
    imports: [AppDictionaryItemConvivialNameComponent, CommonModule, SharedModule],
})
export class DictionariesModule {
}
