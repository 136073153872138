import {inject, Injectable} from '@angular/core';
import {from, Observable, of, switchMap, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {EstimationNotestimFactory} from '@models/estimations/estimation/notestim/estimation-notestim.factory';
import Estimation from '@models/estimations/estimation/estimation.model';
import EstimationNotestim from '@models/estimations/estimation/notestim/estimation-notestim.model';
import {ModalService} from '@shared/modal/modal.service';
import Etude from '@models/etudes/etude/etude.model';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class EstimationNotestimService {
    private _estimationNotestimFactory = inject(EstimationNotestimFactory);
    private _modalService = inject(ModalService);
    private _router = inject(Router);

    getOne$(estimation: Estimation): Observable<EstimationNotestim> {
        return this._estimationNotestimFactory.get$(estimation, {headers: {handledStatusErrors: [404]}}).pipe(
            catchError((httpErrorResponse: HttpErrorResponse) => {
                if (httpErrorResponse.status === 404) {
                    return of(this._estimationNotestimFactory.createVirgin());
                }

                return throwError(() => httpErrorResponse);
            }),
        );
    }

    save$(estimation: Estimation): Observable<EstimationNotestim> {
        return this._estimationNotestimFactory.save$(estimation, {headers: {handledStatusErrors: [400]}}).pipe(
            catchError((httpErrorResponse: HttpErrorResponse) => {
                if (httpErrorResponse.status === 400) {
                    return this._modalService.openConfirmation$({
                        buttonConfirmationLabel: 'Saisir l\'adresse',
                        comments: 'Afin de bénéficier de la puissance de ' + Etude.enablableModules.NOTESTIM.label +
                            ', il est nécessaire de saisir l\'adresse complète de votre bien en estimation.',
                        question: 'Voulez-vous saisir l\'adresse complète de votre bien en estimation ?',
                        title: 'Localisation manquante',
                        status: ModalService.status.WARNING,
                    }).pipe(switchMap(isConfirmed => {
                        if (isConfirmed) {
                            return from(this._router.navigateByUrl('/app/estimations/' + estimation.id.toString() + '/edit/general')).pipe(map(() => undefined!));
                        }

                        return of(this._estimationNotestimFactory.createVirgin());
                    }));
                }

                return throwError(() => httpErrorResponse);
            }),
        );
    }
}
