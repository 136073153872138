import angularJS from '@shared/angularJS/global.ng';
import {IModule, IQService} from 'angular';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {NgEstimation} from '@legacy/app/managers/ressources';
import Estimation from '@models/estimations/estimation/estimation.model';

export default function getEstimationsUtils(module: IModule): void {
    (function (angular) {
        'use strict';

        module.service('EstimationsUtils', Service);

        /**
         * Utils for estimation
         *
         * @param SoqSweetAlert
         * @param $translate
         * @param $q
         */
        Service.$inject = ['SoqSweetAlert', '$translate', '$q'];
        function Service(this: any,
                         soqSweetAlert: NgSoqSweetAlert,
                         $translate: angular.translate.ITranslateService,
                         $q: IQService) {
            this.remettreRapport = remettreRapport;

            /**
             * Remettre rapport
             *
             * @param estimation
             * @returns {Promise}
             */
            function remettreRapport(estimation: NgEstimation) {
                if (estimation.statut !== Estimation.statuts.BROUILLON) {
                    return $q.resolve();
                }

                return soqSweetAlert.warningConfirmation($translate.instant('estimation.remettre.confirm.TITLE', {type: estimation.type.code}), $translate.instant('estimation.remettre.confirm.MESSAGE'), {confirmButtonText: $translate.instant('estimation.remettre.confirm.CONFIRMATION')}).then(function () {
                    return estimation.remettre();
                });
            }
        }
    })(angularJS);
}
