import {inject, Injectable} from '@angular/core';
import {ModelApiService} from '@models/model.api.service';
import {Observable} from 'rxjs';
import {IEstimationNotestimApi} from '@models/estimations/estimation/notestim/estimation-notestim.interfaces';
import {HttpOptions} from '@core/api/api.interfaces';

@Injectable({providedIn: 'root'})
export class EstimationNotestimApiService {
    private _modelApiService = inject(ModelApiService);

    get$(estimationUuid: string, httpOptions?: HttpOptions): Observable<IEstimationNotestimApi> {
        return this._modelApiService.get$(`/estimations/${estimationUuid}/notestim`, httpOptions);
    }

    save$(estimationUuid: string, httpOptions?: HttpOptions): Observable<IEstimationNotestimApi> {
        return this._modelApiService.post$<IEstimationNotestimApi>(`/estimations/${estimationUuid}/notestim`, undefined, httpOptions);
    }
}
