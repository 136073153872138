@if (isCamillaRendering) {
  <ng-container [ngTemplateOutlet]="layoutDossierFiles"/>
} @else {
  <div class="tw-h-full xl:tw-mx-28"><ng-container [ngTemplateOutlet]="layoutDossierFiles"/></div>
}

<ng-template #layoutDossierFiles>
  <div class="tw-flex tw-flex-col tw-gap-4 tw-h-full tw-mx-[-15px] md:tw-mx-0">
    @if (!currentDossier.isArchived()) {
      <esk-upload-files-info [updateTasks$]="updateTasks$" [updateTasksErrors$]="updateTasksErrors$" [typeFile]="'file'"
                             class="tw-block" [infoClientFilter]="UPLOAD_INFOS_CLIENT_FILTER"/>
    }

    @if (cDossierFiles$ | async; as cDossierFiles) {
      @if (cDossierFiles.total > 0) {
        <div class="tw-flex tw-gap-4 tw-flex-col tw-h-full">
          <div class="tw-px-4 md:tw-px-0">
            @if (hasSelectedItems) {
              <app-collection-selection-bar class="tw-block tw-overflow-hidden md:tw-rounded-lg"
                                            [subText]="subTextSelection" [listName]="listOptions.nameSelection!"/>
            } @else {
              <div class="tw-w-full tw-flex tw-justify-between tw-items-center">
                <div><!-- Utile pour le flex et le placement du CTA quand pas d'items -->
                  @if (cDossierFiles.total > 0) {
                    <span class="tw-font-bold">{{ cDossierFiles.total }}</span>
                    {{ cDossierFiles.total > 1 ? 'documents' : 'document' }}
                  }
                </div>

                @if (!isCamillaRendering) {
                  <app-call-to-action type="{{ACTIONS_MAIN}}"/>
                }
              </div>
            }
          </div>

          <app-dossier-files-list (dossierFilesSelected)="dossierFilesSelected($event)"
                                  [cDossierFiles$]="cDossierFiles$" [sharingDossierFileUuids]="sharingDossierFileUuids"
                                  (clicked)="clicked($event)" [options]="listOptions"/>

          <ng-container [ngTemplateOutlet]="addDossierFiles"/>
        </div>
      } @else {
        @if (currentDossier.isArchived()) {
          <div class="tw-w-full tw-mb-4 sm:tw-mb-8 tw-pt-6 tw-px-4 tw-text-center">
            <p class="tw-mb-4 tw-text-base tw-text-gray-500 sm:tw-text-lg md:tw-text-xl">
              Aucun document n'a été attaché à votre bien en vente.
            </p>
          </div>
        } @else {
          <ng-container [ngTemplateOutlet]="addDossierFiles"/>
        }
      }

      <ng-template #addDossierFiles>
        @if (!currentDossier.isArchived()) {
          <div class="tw-flex-1 tw-mx-4 md:tw-mx-0 tw-pb-8">
            <button class="tw-h-full tw-w-full tw-border-dashed tw-border-2 tw-rounded-lg tw-p-6 tw-text-gray-500 tw-text-2xl"
                    type="button" appFormFileDropper (droppedFile)="importFile($event)">
              <app-form-file-picker [options]="{allowedTypes: CONTENT_ALLOWED_TYPES, label: 'Déposer vos documents ici', withIcon: true}"
                                    (selected)="importFile($event)"/>
            </button>
          </div>
        }
      </ng-template>
    } @else {
      <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
    }
  </div>
</ng-template>
